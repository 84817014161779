<template>
  <div v-if="clientId">
    <!-- <v-btn v-google-signin-button="clientId" :loading="loginLoading"
            :disabled="loginLoading" class="login-with-google-btn">&nbsp; &nbsp; Continue with Google</v-btn> -->
    <v-btn
      block
      :loading="loginLoading"
      @click="signInWithGoogle"
      :disabled="loginLoading"
      class="login-with-google-btn"
      >
        <v-img max-width="18px" height="18px" class="mr-2" src="../assets/google-icon.svg"></v-img>
        <span>Continue with Google</span>
      </v-btn
    >
  </div>
</template>

<script>
import GoogleSignInButton from "vue-google-signin-button-directive";
export default {
  directives: {
    GoogleSignInButton,
  },
  data() {
    return {
      clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
      loginLoading: false,
    };
  },

  methods: {
    signInWithGoogle() {
      // try {
      //   auth2.signOut();
      //   auth2.disconnect();
      // } catch (e) {
      //   console.log(e);
      // }
      window.gapi.load("auth2", () => {
        window.gapi.auth2
          .init({
            client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
          })
          .then(() => {
            const auth2 = window.gapi.auth2.getAuthInstance();
            try {
              auth2.signOut();
              auth2.disconnect();
            } catch (e) {
              console.log(e);
            }
            auth2.signIn().then((googleUser) => {
              const token = googleUser.getAuthResponse().id_token;
              this.loginLoading = true;
              // Receive the idToken and make your magic with the backend
              // console.log(Intl.DateTimeFormat().resolvedOptions().timeZone)
              var req = {
                token: token,
                timezone: "Asia/Kolkata",
              };
              this.$http.post(`users/google-login`, req).then((response) => {
                if (response.data.status) {
                  
                  this.$setToken(
                    response.data.data.token,
                    response.data.data.user
                  );
                  this.loginLoading = false;
                  this.$closeLoginModal();
                  
                  const redirectPath = this.$route.query.redirect || '/pricing';

                  this.$router.push(redirectPath);
                }
              });
            });
          });
      });
    },

    OnGoogleAuthSuccess(token) {
      this.loginLoading = true;
      // Receive the idToken and make your magic with the backend
      // console.log(Intl.DateTimeFormat().resolvedOptions().timeZone)
      var req = {
        token: token,
        timezone: "Asia/Kolkata",
      };
      this.$http.post(`users/google-login`, req).then((response) => {
        if (response.data.status) {
          console.log(response.data.data.token);
          this.$setToken(response.data.data.token, response.data.data.user);
          this.loginLoading = false;
          this.$closeLoginModal();
          window.location.href = "/";
        }
      });
    },
    OnGoogleAuthFail(error) {
      console.log(error);
    },
  },
};
</script>

<style scoped>
.login-with-google-btn {
  transition: background-color 0.3s, box-shadow 0.3s;

  padding: 12px 16px 12px 42px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);

  color: #757575;
  font-size: 14px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

  /* background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=); */
  background-color: white;
  /* background-repeat: no-repeat; */
  /* background-position: 12px 11px; */
}

.login-with-google-btn:hover {
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25);
}

.login-with-google-btn:active {
  background-color: #eeeeee;
}

.login-with-google-btn:focus {
  outline: none;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25),
    0 0 0 3px #c8dafc;
}

.login-with-google-btn:disabled {
  filter: grayscale(100%);
  background-color: #ebebeb;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

.v-btn__content {
  align-items: center;
  color: inherit;
  display: flex;
  flex: 1 0 auto;
  justify-content: inherit;
  line-height: normal;
  position: relative;
  transition: inherit;
  transition-property: opacity;
  margin-left: 20px;
}

body {
  text-align: center;
  padding-top: 2rem;
}
</style>
