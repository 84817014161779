<template>
   <v-card flat class="px-4">
      <v-card class="app-item-list" elevation="0" tile>
         <v-list-item class="px-0 app-item" v-for="app in appList" :key="app.id">
            <v-list-item-content class="py-5">
               <div class="d-flex flex-column flex-md-row align-start app-item-details justify-space-between">
                  <div class="d-flex align-center">
                     <img :src="$getStorageURL(app.image_path)" class="app-icon me-3 rounded-lg" alt="">
                     <div>
                        <h6 class="app-title d-flex align-center">
                           <span>{{ app?.name }}</span>
                        </h6>
                        <!-- <p class="app-subtitle mt-2 mb-0">Last reviewed: {{ app.lastReviewed }}</p> -->
                     </div>
                  </div>
                  <div class="d-flex align-center">
                     <ul class="users-list d-flex ma-0 pa-0">
                        <li class="rounded-circle d-flex align-center justify-center user" 
                           v-for="user in app.users.slice(0, 5)"
                           :key="user.id"
                        >
                           <v-menu open-on-hover top left offset-y>
                              <template v-slot:activator="{ on, attrs }">
                                 <v-btn color="white" icon dark v-bind="attrs" v-on="on">
                                    {{getUserInitials(user.first_name)}}
                                 </v-btn>
                              </template>

                              <div class="tooltip-content d-flex align-center pa-3">
                                 <span class="">{{  user.first_name }} {{  user.last_name }}</span>
                                 <!-- <v-btn color="red" class="px-0 ms-6" text small @click="openUserDialog(user)">Remove</v-btn> -->
                              </div>
                           </v-menu>
                        </li>
                        <v-menu open-on-hover top left offset-y  v-if="app.users.length > 5">
                           <template v-slot:activator="{ on, attrs }">
                              <v-btn color="white" icon dark height="40" v-bind="attrs" v-on="on" class="rounded-circle d-flex align-center justify-center extra-users">
                                 +{{ app.users.length - 5 }}
                              </v-btn>
                           </template>

                           <div class="tooltip-content d-flex flex-column pt-4 px-4 pb-2">
                              <div v-for="user in app.users.slice(5)" :key="user.id" class="d-flex align-center mb-2 justify-space-between">
                                 <span class="">{{  user.first_name }} {{  user.last_name }}</span>
                                 <!-- <v-btn color="red" class="px-0 ms-6" text small @click="openUserDialog(user)">Remove</v-btn> -->
                              </div>
                           </div>
                        </v-menu>
                     </ul>
                  </div>
               </div>
            </v-list-item-content>
         </v-list-item>
         <div v-if="!appList?.length" class="text-subtitle-2 py-4">
            No Apps in this workspace
         </div>

      </v-card>

      <v-dialog v-model="showUserDialog" width="500">
         <v-card class="px-4 py-6">
            <p class="mb-4 text-center">
               Are you sure you want to remove {{ dialogUser ? dialogUser.name : '' }}
            </p>
            <div class="d-flex align-center justify-center">
               <v-btn color="red" depressed class="me-2" dark @click="deleteUser(dialogUser); closeUserDialog()">
                  Remove
               </v-btn>
               <v-btn color="#7356F6" class="ms-2" text @click="closeUserDialog()">
                  Close
               </v-btn>
            </div>
         </v-card>
      </v-dialog>
   </v-card>
</template>

<script>
export default {
   name: "AppsListCard",
   props: {
      data: Object,
   },
   data() {
      return {
         isUserActive: false,
         activeUserId: null,
         dialogUser: null,
         showUserDialog: false,
         appList: [],
      };
   },

   methods: {
      makeUserActive(userId) {
         this.activeUserId = userId;
      },

      getUserInitials(str) {
         const firstLetters = str
            .split(' ')
            .map(word => word.charAt(0))
            .join('')
            .slice(0, 2);

         return firstLetters;
      },

      openUserDialog(user) {
         this.dialogUser = user;
         this.showUserDialog = true;
      },

      closeUserDialog() {
         this.dialogUser = null;
         this.showUserDialog = false;
      },

      deleteUser(userToDelete) {
         if (userToDelete) {
            this.appList.forEach(app => {
               const index = app.users.findIndex(user => user.id === userToDelete.id);
               if (index !== -1) {
                  app.users.splice(index, 1);
               }
            });
         }
      },
   },

   mounted() {
      this.appList = this.data?.apps
   }
   
};
</script>

<style lang="scss" scoped>
   .app-heading{
      font-size: .875rem;
   }

   .app-item-list{

      .app-item-details{
         gap: 1.5rem;

         .app-title,
         .app-subtitle{
            font-size: .875rem;
         }
      }
   }

   .app-item{
      &:not(:first-child) {
         border-top: 1px solid lightgrey;
      }
   }

   .users-list{
      list-style-type: none;

      .user{
         width: 2.5rem;
         aspect-ratio: 1;
         background-color: rgb(180, 179, 179);
         color: #ffffff;
         text-transform: uppercase;
         border: 2px solid #ffffff;
         cursor: pointer;
         
         &:not(:first-child) {
            margin-left: -.625rem;
         }

         &:hover {
            border: 2px solid #7356F6;
            z-index: 1;
         }
      }

      .extra-users{
         width: 2.5rem;
         aspect-ratio: 1;
         background-color: rgb(180, 179, 179);
         color: #ffffff;
         margin-left: -.625rem;
         border: 2px solid #ffffff;
      }
   }

   .tooltip-content{
      background-color: #ffffff;
   }

   .app-icon{
      max-width: 2.5rem;
      aspect-ratio: 1;
      object-fit: cover;
   }

</style>