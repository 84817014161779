<template>
  <v-row>
    <v-col cols="12" v-if="!showVerifyEmail">
      <validation-observer v-slot="{handleSubmit}">
        <v-form @submit.prevent="handleSubmit(sendVerificationEmail)">
          <validation-provider rules="required" v-slot="{ errors }">
            <v-text-field
              v-model="firstName"
              label="First Name"
              type="text"
              :error-messages="errors"
              class="mt-2"
              :disabled="sendVerificationEmailLoading"
            ></v-text-field>
          </validation-provider>
          <validation-provider rules="required" v-slot="{ errors }">
            <v-text-field
              v-model="lastName"
              label="Last Name"
              type="text"
              :error-messages="errors"
              class="mt-2"
              :disabled="sendVerificationEmailLoading"
            ></v-text-field>
          </validation-provider>
          <validation-provider rules="required|email" v-slot="{ errors }">
            <v-text-field
              v-model="email"
              label="Email"
              type="email"
              :error-messages="errors"
              class="mt-2"
              :disabled="sendVerificationEmailLoading"
            ></v-text-field>
          </validation-provider>
          <validation-provider rules="required" v-slot="{ errors }">
            <v-text-field
              v-model="password"
              label="Password"
              type="password"
              :error-messages="errors"
              class="mt-2"
              :disabled="sendVerificationEmailLoading"
            ></v-text-field>
          </validation-provider>
          <v-btn
            color="primary"
            type="submit"
            block
            class="mt-2"
            :loading="sendVerificationEmailLoading"
            :disabled="sendVerificationEmailLoading"
          >Register</v-btn>
        </v-form>
      </validation-observer>
      <div class="mt-5 text-center"><div class="mb-5"><GoogleSignIn></GoogleSignIn></div></div>
    </v-col>
    <v-col cols="12" v-if="showVerifyEmail">
      <validation-observer v-slot="{handleSubmit}">
        <v-form @submit.prevent="handleSubmit(register)">
          <validation-provider rules="required" v-slot="{ errors }">
            <v-text-field
              v-model="otp"
              label="Enter OTP"
              type="number"
              :error-messages="errors"
              class="mt-2"
              :disabled="registerLoading"
            ></v-text-field>
          </validation-provider>
          <v-btn
            color="primary"
            type="submit"
            block
            class="mt-2"
            :loading="registerLoading"
            :disabled="registerLoading"
          >Verify OTP</v-btn>
        </v-form>
      </validation-observer>
    </v-col>
  </v-row>
</template>

<script>

import GoogleSignIn from "@/components/GoogleSignIn.vue";

export default {
  name: "LoginVue",
  components: {
    GoogleSignIn
  },
  data() {
    return {
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      timezone: "",
      otp: "",
      showVerifyEmail: false,
      sendVerificationEmailLoading: false,
      registerLoading: false
    };
  },
  mounted() {
    this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  },
  methods: {
    sendVerificationEmail() {
      this.sendVerificationEmailLoading = true
      this.$http
        .post(`/users/send-verification-email`, { email: this.email })
        .then(response => {
          if (response.data.status) {
            this.showVerifyEmail = true;
            
          } else {
            //
          }
        })
        .catch((error) => { 
          console.log(error)
        })
        .finally(() => {
          this.sendVerificationEmailLoading = false;
        });
    },
    register() {
      this.registerLoading = true
      this.$http
        .post(`/users/register`, {
          first_name: this.firstName,
          last_name: this.lastName,
          email: this.email,
          password: this.password,
          timezone: this.timezone,
          verification_code: this.otp
        })
        .then(response => {
          if (response.data.status) {
            this.$setToken(response.data.data.access_token, response.data.data.user);
            const redirectPath = this.$route.query.redirect || '/';

            this.$router.push(redirectPath);
          } else {
            // this.message = response.data.message;
          }
        })
        .catch((error) => { 
          console.log(error)
        })
        .finally(() => {
          this.registerLoading = false;
        });
    }
  }
};
</script>
