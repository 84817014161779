import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import {
  required,
  confirmed,
  email,
  image,
  size,
} from "vee-validate/dist/rules";
import { mapGetters } from "vuex";


/* eslint-disable */
const baseURI = process.env.VUE_APP_API_URL;
axios.defaults.baseURL = baseURI;



Vue.config.productionTip = false;

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

// Vue.use(axios);
const accessToken = localStorage.getItem("accessToken");
const activeWorkspaceId = localStorage.getItem("activeWorkspace");
if(accessToken)
    store.dispatch("accessToken", accessToken);
if(activeWorkspaceId)
    store.dispatch("storeActiveWorkspace", activeWorkspaceId);
axios.defaults.headers.common[
    "Authorization"
] = `Bearer ${accessToken}`;
    

extend("confirmed", {
  ...confirmed,
  message: "Password does not match",
});

extend("required", {
  ...required,
  message: "This field is required",
});

extend("image", {
  ...image,
  message: "This field must be an Image",
});

extend("size", {
  ...size,
  message: "Max file size limit exceeded",
});

extend("email", {
  ...email,
  message: "Invalid Email",
});

Vue.mixin({
  computed: {
    ...mapGetters(["isLoggedIn"]),
  },
  methods: {
    $getStorageURL(path) {
      return `${process.env.VUE_APP_STORAGE_URL}/${path}`;
    },
    $openLoginModal() {
      store.dispatch("openLoginModal");
    },
    $closeLoginModal() {
      store.dispatch("closeLoginModal");
    },
    $setUserInfo() {
      const accessToken = localStorage.getItem("accessToken");
      // console.log(typeof accessToken);
      if (accessToken && accessToken != "null") {
        const user = JSON.parse(localStorage.getItem("user"));
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${accessToken}`;
        store.dispatch("userLoggedIn");
        store.dispatch("storeUserInfo", user);
        // const shareToken = localStorage.getItem("shareToken");
        const shareToken = this.$route.params.appId;
        if (shareToken) {
          axios.post(`/apps/share/accept`, { app_id: shareToken });
          // .then((response) => {
          //     if (response.status) {
          //         localStorage.removeItem("shareToken");
          //     }
          // });
        }
      } else {
        axios.defaults.headers.common["Authorization"];
        store.dispatch("userLoggedOut");
       

   
      }
    },
    $setToken(token, user) {
      localStorage.setItem("accessToken", token);
      localStorage.setItem("user", JSON.stringify(user));
      store.dispatch("accessToken", token);
      this.$setUserInfo();
    },
    $clearToken() {
      localStorage.clear();

      this.$setUserInfo();
    },
  },
});

Vue.prototype.$http = axios;


axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  }, function (error) {
    console.log(error);
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if(error.response.status === 401){ 
        localStorage.clear();
        window.location.href = "/"
    }
    return Promise.reject(error);
  });            

  
const urlParams = new URLSearchParams(window.location.search);
const loginToken = urlParams.get('login-token');
const action = urlParams.get('action');
let redirectPath = ""
if(loginToken){
    axios.post(
        `/users/token-login`,
        {
            token:loginToken
        }
    ).then((response)=>{              
        if(response.data.status){
            let token = response.data.data.access_token
            let user = response.data.data.user            
            localStorage.setItem("accessToken", token);
            localStorage.setItem("user", JSON.stringify(user));
            store.dispatch("accessToken", token);
            store.dispatch("userLoggedIn");
            store.dispatch("storeUserInfo", user);            
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${token}`;
                
            if(action){
              if(action == "workspace_settings"){
                var workspaceId = urlParams.get('workspace_id');
                var url = `/settings/${workspaceId}`                
                redirectPath = url
              } 
              else
              redirectPath = action                
            }else{
              redirectPath = ""                
            }
            
        }
        else{          
          redirectPath = ""            

        }
        
    }).catch(()=>{
      // initVue()   
    }).finally(()=>{
      if(redirectPath)
        localStorage.setItem("redirect_path",redirectPath)        
      initVue()
      
      
    })
    
}
else{

  initVue()
  
}



function initVue(){
  // return null
  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");
  
}