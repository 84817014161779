<template>
  <v-row class="ma-0">
    <v-col class="col col-12 col-md-8 d-none d-md-flex flex-md-column full-height">
      <div class="d-flex align-center pt-2 header">
        <v-img src="@/assets/bugsmash-icon-squirle.png"  class="rounded-circle logo-image mr-3"></v-img>
        <h3 class="mb-0 font-weight-bold text-h4 logo-text white--text">BugSmash</h3>
      </div>
      <div class="flex-grow-1 d-flex align-center justify-center ">
        <div class="d-flex align-center justify-center ">
          <h1 class="mb-0 white--text font-weight-bold image-text pl-8 pr-4">
            Easiest way <br> to share <br> app feedback
          </h1>
          <v-img max-width="480" height="auto" src="../assets/login-page-image.png"></v-img>
        </div>
      </div>
    </v-col>
    <v-col class="col col-12 col-md-4 px-0 d-flex align-center">
      <div class="loginCard pa-5">

        <div v-if="mode === 'login'">
          <div class="text-h5 text-capitalize mb-5 d-flex justify-start">
            <img src="@/assets/bugsmash-icon-squirle.png" style="width: 60px" />
          </div>
          <p class="font-weight-black text-h4">Sign in</p>
          <login :key="mode"></login>
          <div class="mt-5 text-start">
            New to bugsmash?
            <a href="javascript:void(0);" @click="changeMode('register')">Register</a>
          </div>
        </div>
        <div v-if="mode === 'register'">
          <div class="text-h5 text-capitalize mb-5 d-flex justify-start">
            <img src="@/assets/bugsmash-icon-squirle.png" style="width: 60px" />
          </div>
          <p class="font-weight-black text-h4">Sign up</p>
          <register :key="mode"></register>
          <div class="mt-5 text-start">
            Already have an account?
            <a href="javascript:void(0);" @click="changeMode('login')">Login</a>
          </div>
        </div>


      </div>

    </v-col>
  </v-row>
</template>

<script>
import Login from "../components/Login.vue";
import Register from "../components/Register.vue";

export default {
  name: "RedirectLogin",
  components: {
    Login, Register
  },
  data() {
    return {
      email: "",
      password: "",
      loginLoading: false,
      message: "",
      mode: "login",
    };
  },
  methods: {
    login() {
      this.loginLoading = true;
      this.$http
        .post(`/users/login`, { email: this.email, password: this.password })
        .then((response) => {
          if (response.data.status) {
            this.$setToken(
              response.data.data.access_token,
              response.data.data.user
            );
            this.$closeLoginModal();

            const redirectPath = this.$route.query.redirect || '/';

            this.$router.push(redirectPath);
          } else {
            this.message = response.data.message;
          }
        })
        .finally(() => {
          this.loginLoading = false;
        });
    },
    changeMode(mode) {
      this.mode = mode;
    },
  },
};
</script>

<style lang="scss" scoped>
// .center {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   margin: 0 auto;
// }

// .section{
//         min-height: 100%;
//     }

.header{
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
}

.logo-text {
  cursor: default;
  font-size: clamp(1rem, 2vw, 1.5rem) !important;
  font-weight: 800 !important;
  font-family: "Open Sans", sans-serif !important;
}

.logo-image{
  cursor: default;
  width: 2rem;
  max-width: 2.5rem;
}

.full-height {
  min-height: 100vh;
  background-color: #150c2d;
}

.image-text{
  line-height: 1.4;
  font-size: 3.25rem;
}

.v-application .primary {
  background-color: #7e64ed !important;
  border-color: #1976d2 !important;
}

a {
  color: #7e64ed !important;
}

.loginCard {
  margin: 0 auto;
  width: 90%;
  padding: 50px;


}

@media only screen and (min-width: 960px) {
  .logo-image{
    max-width: 2.75rem;
  }
}

// v-col{
//     height: calc(100vh - 280px);
// }
</style>
