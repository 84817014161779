<template>
  <v-container fluid class="pa-4 pa-md-6">
    <h3 class="reported-bugs-heading font-weight-bold mb-2">Reported Bugs</h3>

    <v-list class="bg-white">
      <v-list-item
        @mouseenter="addHighlight(review.id)"
        @mouseleave="removeHighlight(review.id)"
        @click="toggleHighlight(review.id)"
        v-for="(review, i) in reviews"
        :key="i"
        :class="'px-2 py-2 bug-report '+(getHighlightedReviewIds.includes(review.id) ? 'highlighted' : '')"
        style="border-bottom: 1px solid #ddd;"
      >
        <v-list-item-content>
          <v-container fluid class="d-flex align-start pa-0">
            <span
              :class="`report-number report-number--${review.status === 'closed' ? 'closed' : 'open'} font-weight-bold d-flex flex-wrap align-center justify-center`"
            >{{ review.order }}</span>
            <v-container fluid class="pa-0" justify-space-between>
              <p class="report-text mb-3">{{ review.review }}</p>
              <div class="mx-0 d-flex align-center report-actions">
                <update-bug-status :review="review"></update-bug-status>
                <span class="text-caption grey--text">
                  {{ $formatDate(review.updated_at) }}
                </span>
              </div>
            </v-container>
          </v-container>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import UpdateBugStatus from "@/components/UpdateBugStatus";
import { globalMixin } from "@/utils/globalMixins";

export default {
  components: {
    UpdateBugStatus
  },
  mixins: [globalMixin],
  computed: {
    ...mapGetters(["getHighlightedReviewIds", "getLockedReviewIds"])
  },

  methods: {
    toggleHighlight(reviewId) {
      if (this.getLockedReviewIds.includes(reviewId)) {
        this.removeHighlight(reviewId, true);
      } else {
        this.addHighlight(reviewId, true);
      }
    },
    addHighlight(reviewId, lock = false) {
      if (lock) {
        this.$store.dispatch("addLockedReviewId", reviewId);
      }
      this.$store.dispatch("addHighlightedReviewId", reviewId);
    },
    removeHighlight(reviewId, removeLock = false) {
      if (removeLock) {
        this.$store.dispatch("removeLockedReviewId", reviewId);
      }
      if (!this.getLockedReviewIds.includes(reviewId)) {
        this.$store.dispatch("removeHighlightedReviewId", reviewId);
      }
    }
  },

  name: "VisualizrCards",

  props: ["reviews"],
};
</script>

<style lang="scss" scoped>

.bg-white{
  background-color: white;
}
.bug-report {
  &.highlighted {
    background-color: #efefef;

    .report-number {
      border: 2px solid rgb(126, 100, 237);
    }
  }
}

.reported-bugs-heading {
  font-size: 1rem;
  line-height: 1;
}

.report-number {
  font-size: 1.5rem;
  color: #fff;
  min-width: 3.125rem;
  width: 3.125rem;
  border-radius: 50%;
  aspect-ratio: 1;
  margin-right: 0.875rem;

  &--open {
    background-color: #d06262;
  }

  &--closed {
    background-color: #6dc590;
  }
}

.report-text {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.4;
  color: #000;
}

.report-actions {
  gap: 0.5rem;
}

@media only screen and (min-width: 600px) {
  .reported-bugs-heading {
    font-size: 1.25rem;
  }
}
@media only screen and (min-width: 600px) {
  .bg-white{
    background-color: transparent;
  }
}
</style>