<template>
   <div class="slider-container" :class="[isOpen ? 'open' : 'close']" :style="position">
      <button class="slider-btn" @click="isOpen = !isOpen"></button>
      <div class="content-container">
         <slot></slot>
      </div>
   </div>
</template>

<script>
export default {
   
   data() {
      return {
         isOpen: false,
         touch: {
            startY : 0,
            endY: 0
         },
      }
   },

   computed: {
      position() {
         return {transform: 'translateY(-calc(100vh - 8rem))'}
      }
   },

   methods: {
      openContainer() {
         this.isOpen = true
      },

      closeContainer() {
         this.isOpen = false
      },

      touchstart(event) {
        this.touch.startY = event.touches[0].clientY
        this.touch.endY = 0
      },

      touchmove(event) {
         this.touch.endY = event.touches[0].clientY
      },

      touchend() {
         if(!this.touch.endY || Math.abs(this.touch.endY - this.touch.startY) < 20) return;

         if(this.touch.endY < this.touch.startY) {
            this.openContainer()
         } else {
            this.closeContainer()
         }
      },
   },

   mounted() {
      this.$el.addEventListener('touchstart', event => this.touchstart(event));

      this.$el.addEventListener('touchmove', event => this.touchmove(event));

      this.$el.addEventListener('touchend', () => this.touchend());
   }

}
</script>

<style lang="scss" scoped>

.slider-container {
   background-color: #fff;
   border-radius: 1.25rem 1.25rem 0 0;
   position: fixed;
   bottom: 0;
   min-width: 100vw;
   height: calc(100vh - 8rem);
   max-height: calc(100vh - 8rem);
   transition: all .5s ease;
}

.content-container{
   overflow: auto;
   max-height: calc(100% - 2.5rem);
   height: calc(100% - 2.5rem);
}

.slider-btn{
   position: relative;
   min-width: 100%;
   height: 2.5rem;
   border-radius: 1.25rem 1.25rem 0 0;

   &::before {
      content: '';
      position: absolute;
      top: 1rem;
      left: 50%;
      transform: translateX(-50%);
      height: 2px;
      border-radius: .5rem;
      background-color: #000;
      width: 30%;
   }
}

.open{
   top: calc(100vh - (100vh - 8rem));
}

.close{
   top: calc(100vh - 5rem);
}

@media only screen and (min-width: 600px){
   .open{
      top: calc(100vh - (100vh - 8.5rem));
   }
}

@media only screen and (min-width: 960px){
   .open{
      top: calc(100vh - (100vh - 9rem));
   }
}

</style>

