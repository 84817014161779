<template>
  <v-app>
    <v-app-bar
      app
      elevate-on-scroll
      color="#060310"
      class="custom-app-bar py-1 py-sm-2"
      v-if="!$route.meta.hideNavbar" 
    >
      <v-container
        fluid
        color="#fff"
        class="mx-auto d-flex align-center justify-space-between px-0 px-lg-4 custom-container custom-wrapper"
        height="auto"
      >
        <v-img @click="goToHomePage()" class="rounded-circle logo-image" aspect-ratio="1" max-height="auto" src="@/assets/bugsmash-icon-squirle.png"></v-img>
        <span @click="goToHomePage()" class="logo-text ps-2 d-none d-sm-inline-block">BugSmash</span>

        <v-divider vertical dark style="border-color: white;" class="d-none d-sm-block ms-5 me-2"></v-divider>
        <workspace-menu-card/>
        <v-spacer></v-spacer>

        <!-- <v-toolbar-items> -->
          <v-btn class="rounded-lg me-sm-4" dark color="#7356F6" :to="'/'+activeWorkspace+'/pricing'" v-if="isOwner && canShowUpgrade() && $route.path != '/pricing'">Upgrade</v-btn>
          <v-btn dark text @click="openCompletePaymentLink()" v-if="isOwner && canShowCompletePayment()">Complete Payment</v-btn>
        <!-- </v-toolbar-items> -->
        <v-menu offset-y v-if="isLoggedIn">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" text class="d-inline-flex user-btn align-center px-0">
              <v-container class="pa-0 user-name-text d-none d-sm-block me-2">{{ fullName }}</v-container>
              <v-icon color="#fff" large>mdi-account-circle</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in items"
              :key="index"
              @click="callFunction(item.method)"
              link
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
          color="#fff"
          text
          class="d-inline-flex align-center py-1"
          v-else
          @click="openLogin"
        >
          Login
        </v-btn>
      </v-container>
    </v-app-bar>

    <v-main :style="$route.meta.hideNavbar ? 'padding-top: 0 !important' : ''">
      <v-dialog v-model="loginDialog" width="500" persistent>
        <v-card class="pa-4">
          <v-row>
            <v-col
              cols="12"
              class=""
              style="display: flex; justify-content: end"
            >
              <v-btn icon @click="$closeLoginModal">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-card-title
            class="text-h5 text-capitalize"
            style="display: flex; justify-content: center"
          >
            <img src="@/assets/bugsmash-icon-squirle.png" style="width: 60px" />
          </v-card-title>
          <v-card-text>
            <div v-if="mode === 'login'">
              <login :key="mode"></login>
              <div class="mt-5 text-center">
                Don't have an account?
                <a href="javascript:void(0);" @click="changeMode('register')"
                  >Register</a
                >
              </div>
            </div>
            <div v-if="mode === 'register'">
              <register :key="mode"></register>
              <div class="mt-5 text-center">
                Already have an account?
                <a href="javascript:void(0);" @click="changeMode('login')"
                  >Login</a
                >
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="workSpaceDialog" width="500">
        <v-card class="pt-4 px-4 pb-2">
          <h3 class="mb-4">Select Workspace</h3>
          <v-container class="pa-0" fluid>
            <v-radio-group v-model="workspaceGroup" class="mt-0 pt-0" hide-details>
              <v-radio
                v-for="n in 3"
                :key="n"
                :label="`WorkSpace ${n}`"
                :value="n"
                class="mb-3"
                color="#7e64ed"
              ></v-radio>
            </v-radio-group>
          </v-container>
        </v-card>
      </v-dialog>
      <!-- <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar dark color="#7e64ed">
            <v-spacer></v-spacer>
            <v-btn icon dark @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <hr />
          <pricing></pricing>
        </v-card>
      </v-dialog> -->
      <router-view />
      
      <ToastVue ref="vtoast"/>
    </v-main>
    <main-footer v-if="!$route.meta.hideFooter"></main-footer>
  </v-app>
</template>

<script>
import Login from "./components/Login.vue";
import Register from "./components/Register.vue";
// import pricing from "./components/Pricing.vue";
import ToastVue from "./components/Toast.vue";
import MainFooter from "./components/MainFooter.vue";
import WorkspaceMenuCard from "./components/WorkspaceMenuCard.vue";
import * as helpers from "./utils/helpers";

import { mapGetters } from "vuex";

export default {
  name: "App",
  components: { Login, Register, ToastVue, MainFooter , WorkspaceMenuCard},
  watch:{
    workspaces(){
      console.log("WPS=" +this.workspaces.length)
      this.getActiveWorkspacePlanDetails()
    },
    activeWorkspace(){      
      this.getActiveWorkspacePlanDetails()
    },
    isLoggedIn(value){
      console.log(value)
    }
  },
  computed: {
    ...mapGetters(["loginDialog", "fullName","user","workspaces","activeWorkspace"]),
    isOwner(){      
      let obj = this.workspaces.find(workspace=>workspace.id == this.activeWorkspace)
      if(obj)
        return obj.created_by == this.user.id
      return false
    }
  },
  methods: {
    openCompletePaymentLink(){
      window.location.href = this.activePlan.url
    },
    canShowUpgrade(){
      
      if(!this.isLoggedIn || this.fetchingWorkspacePlan )
        return false
      
      
      return !this.activePlan.subscription_status?.active
      
    },
    canShowCompletePayment(){
      if(!this.isLoggedIn || this.fetchingWorkspacePlan)
        return false
      if(this.activePlan?.subscription_status){
        return this.activePlan.subscription_status.hasIncompletePayment
      }
      return false
    },
    callFunction(method) {
      this[method]();
    },
    profile() {
      // API LOGOUT
      this.$router.push({ path: '/profile' })
    },
    switchWorkspace() {
      this.workSpaceDialog = true
    },
    home(){
      this.$router.push({ path: '/' })
    },
    logout() {
      // API LOGOUT
      this.$clearToken();      
      // this.$router.push({ path: '/' })
      window.location.href = "/"
    },
    changeMode(mode) {
      this.mode = mode;
    },
    goToHomePage() {
      this.$router.push("/");
    },
    openLogin() {
      this.$openLoginModal();
    },
    async getActiveWorkspacePlanDetails(){
    
      this.activePlan = null
      if(this.isOwner){
        try {        
        
        this.fetchingWorkspacePlan = true
        let res = await helpers.fetchPlanDetails(this.activeWorkspace)          
        this.activePlan = res.data.data 
        this.fetchingWorkspacePlan = false
      } catch (e) {
        console.log(e)
      }
           
      }
     
    }
  },
  async mounted() {
    this.$setUserInfo();
    this.$root.vtoast = this.$refs.vtoast
    this.getActiveWorkspacePlanDetails()
    var redirectPath = localStorage.getItem("redirect_path")
    if(redirectPath){
        this.$router.push(redirectPath)
        localStorage.setItem("redirect_path","")
    }
    // this.$openLoginModal()
    // if (this.$route.query.token) {
    //   localStorage.setItem("shareToken", this.$route.query.token);
    //   this.$router.replace({ query: {} });
    // }
  },

  data() {
    return {
      drawer: null,
      items: [
        { title: "Profile", method: "profile" },
        // { title: "Switch Workspace", method: "switchWorkspace" },
        { title: "Logout", method: "logout" }
      ],
      mode: "login",
      dialog: false, 
      workSpaceDialog: false,
      workspaceGroup: 1,
      fetchingWorkspacePlan:false
    };
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");

#app {
  font-family: "Open Sans", sans-serif !important;
}

// custom scroll bar
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #3a435e #fff;
}
/* Firefox */

/* Chrome, Edge, Safari */
*::-webkit-scrollbar {
  width: 0.75rem; /* vertical scrollbar */
  height: 0.75rem; /* horizontal scrollbar */
}

*::-webkit-scrollbar-track {
  background: #fff;
}

*::-webkit-scrollbar-thumb {
  background: #3a435e;
  border: 2px solid #fff;
}

*::-webkit-scrollbar-thumb:hover {
  background: lighten(#3a435e, 25%);
}
/* Chrome, Edge, Safari */

.custom-container {
  max-width: 1440px;
  margin: 0 auto;
}

.logo-text {
  font-size: clamp(1rem, 2vw, 1.5rem);
  font-weight: 800;
  color: #fff;
  cursor: pointer;
}

.custom-app-bar {
  min-height: 4rem;
}

.v-main {
  padding-top: 4rem !important;
  // background-color: #f2f3f4;
}

.v-btn {
  text-transform: none;
}

.user-name-text {
  font-size: 1rem;
  color: #fff;
  letter-spacing: 0;
}

.logo-image{
  width: 2rem;
  max-width: 2.5rem;
  cursor: pointer;
}

@media only screen and (min-width: 600px) {
  .custom-app-bar {
    min-height: 4.5rem;
  }

  .v-main {
    padding-top: 4.5rem !important;
  }

  
}

@media only screen and (min-width: 960px) {
  .custom-app-bar {
    min-height: 5rem;
  }

  .v-main {
    padding-top: 5rem !important;
  }

  .logo-image{
    max-width: 2.75rem;
  }
}
</style>
