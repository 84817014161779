<template>
  <v-container class="fill-height px-3 px-md-0 py-8 align-start">
    <v-row>
      <v-col cols="12" class="">
        <div class="d-flex align-center justify-space-between mb-3">
          <p class="mb-0 my-app-text">My Apps</p>
          <!-- dialog start -->
          <div class="text-center">
            <v-dialog v-model="dialog" width="520" content-class="elevation-0">
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="review-btn rounded-lg" height="40" outlined color="#7356F6" v-bind="attrs" v-on="on">
                  New App Review
                </v-btn>
              </template>

              <v-card class="dialog-card shadow-none pa-4 text-center d-flex flex-column align-center justify-center" min-height="300">
                <v-card-title class="dialog-card--heading mb-0 pa-0">
                  Download the BugSmash android app & start reviewing your apps
                </v-card-title>
                <v-card-text class="dialog-card--para black--text mb-0 pa-0">
                  Apps can be reviewed from within the BugSmash mobile app. Once you start saving the screen feedbacks,
                  they would appear here.
                </v-card-text>
                <v-card-actions class="pa-0">
                  <v-btn class="rounded-lg mt-2 px-5" dark color="#7356F6" @click="redirectToPlayStore">
                    DOWNLOAD THE APP NOW
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
          <!-- dialog end -->
        </div>
        <p class="mb-0 header-text">
          Click on any of the app to view the reported issues to start a new
          review session.
        </p>
      </v-col>
    </v-row>

    <v-row align="stretch" class="data-container mx-0" v-if="!apps.length && !appsLoader">
      <v-col cols="12" class="d-flex justify-center align-center">
        <div>
          <v-img width="300" height="auto" class="mx-auto img-fluid" src="../assets/no-feedbacks-img.svg"></v-img>
          <p class="mt-3 mb-1 text-center no-data-text">Nothing here yet!</p>
          <p class="mb-0 text-center">
            Download the <span class="text-link" @click="redirectToPlayStore()">BugSmash app</span> and start reviewing your app.
          </p>
        </div>
      </v-col>
    </v-row>

    <section class="data-container" v-if="appsLoader">
      <div class="cards-container pa-4 pa-lg-6">
        <v-skeleton-loader type="card" class="rounded-lg" max-height="180" v-for="n in [1,2,3,4,5,6]" :key="n"></v-skeleton-loader>
      </div>
    </section>

    <section class="data-container" v-if="apps.length > 0">
      <div class="cards-container pa-4 pa-lg-6">
        <v-card class="item-card d-flex flex-column" outlined @click="handleClick(app.id)" v-for="app in apps" :key="app.id">
          <v-avatar class="ma-4 rounded-lg" color="grey lighten-4" tile size="80">
            <img :src="$getStorageURL(app.image_path)" class="app-image" alt="img" />
          </v-avatar>
          <v-divider></v-divider>
          <h6 class="text-subtitle-1 px-3 px-md-4 d-block pt-2 pb-0 font-weight-black d-md-none"> {{ app.name | displayName(10) }}</h6>
          <h6 class="text-subtitle-1 px-3 px-md-4 pt-2 pb-0 font-weight-black d-none d-md-block"> {{ app.name | displayName(15) }}</h6>
          <p class="text-caption px-3 px-md-4 pt-0 grey--text mb-0 pb-3">
            Updated {{ $formatDate(app.updated_at) }}
          </p>
        </v-card>
      </div>
    </section>

    

  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { globalMixin } from "@/utils/globalMixins";

export default {
  name: "MyAppVue",
  computed: {
    ...mapGetters(["isLoggedIn", "activeWorkspace"]),
  },  
  mixins: [globalMixin],
  filters: {
    displayName(val, size) {
      if (!val) return "";
      val = val.toString();

      if (val.length <= size) {
        return val;
      }
      return val.substr(0, size) + "...";
    },
  },
  watch: {
    activeWorkspace(){      
      this.myApp()
    }
  },
  data() {
    return {
      apps: [],
      dialog: false,
      appsLoader: false,
    };
  },
  methods: {
    myApp() {
      this.appsLoader = true
      this.$http
        .post('/apps',{
          workspace_ids:[this.activeWorkspace]
        })
        .then((response) => {
          this.apps = response.data.data;
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.appsLoader = false
        });
    },
    handleClick(val) {
      const appId = val
      this.$router.push({ name: 'visualizr', params: { appId } })

    },
    redirectToPlayStore() {
      window.location.href = 'https://play.google.com/store/apps/details?id=in.solutionwise.bugsmash'
    },
  },
  mounted() {    
    if(this.activeWorkspace)
      this.myApp()
  },

};
</script>

<style lang="scss" scoped>
.my-app-text {
  // font-size: 2.5rem;
  font-size: clamp(1.75rem, 3.5vw, 2.5rem);
  font-weight: 800;
}

.header-text {
  color: lighten(#000000, 70%);
}

.text-link {
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
}

.review-btn {
  border-width: 2px;
  font-weight: 700;
  letter-spacing: 0;
  font-size: 1rem;
}

.data-container {
  background-image: url(../assets/bug-bg.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom 10% right 2.5%;
  background-color: #F2F3F4;
  border-radius: 1.25rem;
  width: 100%;
  min-height: calc(100% - 7.5rem);
}

.no-data-text {
  font-weight: 600;
}

.cards-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: start;
  gap: 1rem;
  width: 100%;
}

.item-card {
  border: 2px solid #7e64ed;
  border-radius: .625rem;
}

.dialog-card {
  border-radius: 1.25rem;
  gap: 1.5rem;

  &--heading,
  &--para {
    max-width: 58ch;
    color: #000;
  }

  &--heading {
    font-weight: 700 !important;
    font-size: 1.5rem;
    line-height: 1.4;
    word-break: unset;
  }
}

  @media only screen and (min-width: 600px) {
    .cards-container {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media only screen and (min-width: 960px) {
    .cards-container {
      grid-template-columns: repeat(4, 1fr);
      gap: 1.5rem;
    }
  }

  @media only screen and (min-width: 1264px) {
    .cards-container {
      grid-template-columns: repeat(5, 1fr);
    }
  }
</style>
