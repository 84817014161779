<!-- <template>
  <div >
    <v-card class="" outlined elevation="0">
      <v-container v-if="initialLoading">
        <v-row class="flex justify-center py-8">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-row>
      </v-container>
      <div v-else>
        <div class="pa-3 my-1 d-flex justify-space-between"> 

            <P class="text-h6">CURRENT PLAN</P>
            <v-card class="ms-3 mb-1" color="#e7e4e5"  v-if="canShowResume()" elevation="1"
              ><P class="px-2 py-1">Canceled</P></v-card
            >
          </div>
            <hr>
        <div class="d-flex justify-space-between">
          <div class="pa-4">
            

            <p class="mb-3 mt-3">
              {{ planDisplayDetails.plan }}
            </p>

            <p
              class="font-weight-black text-h5"
              v-if="planDisplayDetails.price != null"
            >
              {{ planDisplayDetails.currencySymbol }}{{ planDisplayDetails.price }} per
              {{ planDisplayDetails.duration }}
            </p>

            <p class="font-weight-light mb-5" v-if="canShowResume()">
              If you change your mind, you can resume your subscription till
              {{ formatDate(subscriptionEndDate.subscription.ends_at) }}
            </p>
          </div>

          <div class="pa-4 align-center">
            <div class="mt-5">
              <v-btn
              elevation="2"
              class="mt-2"
              v-if="canShowUpgrade()"
              dark
              block
              color="teal"
              @click="dialog = true"
              >Upgrade</v-btn
            >
            <v-btn
              elevation="2"
              dark
              block
              color="teal"
              class="mt-2"
              v-if="canShowManage()"
              @click="dialog = true"
              >Manage</v-btn
            >

            <v-btn
              elevation="2"
              class="mt-2"
              v-if="canShowResume()"
              @click="resumeSubscription()"
              dark
              block
              :loading="resumeLoading"
              color="teal"
              >Resume</v-btn
            >
            <v-btn
              class="mt-2"
              elevation="2"
              block
              @click="openConformationDialog = true"
              v-if="canShowCancel()"
              :loading="cancelLoading"
              color="error"
              >cancel</v-btn
            >
            </div>
            
          </div>
        </div>

        <v-template v-if="canShowCompletePayment()">
          <v-divider></v-divider>
          <div class="d-flex justify-space-between pa-4">
            <p class="d-flex align-center">
              Complete your payment to continue subscription
            </p>
            <div>
              <v-btn
                class="ms-2"
                elevation="2"
                @click="cancelSubscription"
                v-if="canShowCancel()"
                color="error"
                >cancel</v-btn
              >

              <v-btn
                elevation="2"
                class="ms-2"
                @click="complatePayment()"
                dark
                color="teal"
                >Complete Payment</v-btn
              >
            </div>
          </div>
        </v-template>
      </div>
    </v-card>

    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="#7e64ed">
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <hr />
        <pricing @dialog="closeDialog"></pricing>
      </v-card>
    </v-dialog>
    <v-dialog v-model="openConformationDialog" width="500">
      <v-card>
        <v-card-text class="text-center pt-5">
          Your plan will be canceled,<br />
          But is still available until the end of your billing period.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="d-flex justify-center">          
          <v-btn color="primary" text @click="openConformationDialog = false">
            Go Back
          </v-btn>
          <v-btn color="red" text @click="cancelSubscription">
            Cancel Plan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template> -->

<template>
  <div>
    <v-skeleton-loader v-if="initialLoading" type="article, actions"></v-skeleton-loader>

    <div v-if="!initialLoading">
      <h4 class="app-heading mb-4 font-weight-black">{{ planDisplayDetails.plan }} plan</h4>
      <v-card flat class="pa-4">
        <v-row>
          <v-col cols="12">
            <p class="mb-5 mt-2">
              <span class="curr-plan-price me-2">{{ planDisplayDetails.currencySymbol }}{{ planDisplayDetails.price }}</span>
              <span class="per-month">per month</span>
            </p>
            <v-row>
              <v-col cols="12" md="6">
                <div class="d-flex align-center mb-2">
                  <h5 class="pe-3 included-heading">What's Included</h5>
                  <v-divider></v-divider>
                </div>
                <div >
                  <p class="offering-text py-2 d-flex align-start" v-for="feature in planDisplayDetails.features" :key="feature">
                    <v-icon class="me-3 rounded-circle tick-icon" color="#7356F6">mdi-check</v-icon>
                    <span>{{ feature }}</span>
                  </p>
                </div>
              </v-col>

              <v-divider vertical class="mt-10 d-none d-md-block mb-4"></v-divider>

              <v-col cols="12" md="6">
                <div class="d-flex align-center mb-2">
                  <h5 class="pe-3 included-heading">Manage Plan</h5>
                  <v-divider></v-divider>
                </div>
                <p class="mb-0 not-subscribed-text" v-if="canShowUpgrade()">You are currently not subscribed to any plan</p>
                <div class="mt-4" v-if="canShowResume()">
                  <p class="text-caption font-weight-semibold">
                    Your plan is currently <span class="font-weight-bold">cancelled.</span> If you change your mind, you can resume your subscription till
                    {{ formatDate(subscriptionEndDate.subscription.ends_at) }}.
                  </p>
                </div>
                <div class="mt-4" v-if="canShowCancel()">
                  <p class="text-caption font-weight-semibold">
                    You are currently subscribed to the <span class="font-weight-bold">{{ planDisplayDetails.plan }}</span> plan.                    
                  </p>
                </div>
                <v-row class="pt-6 pb-4">
                  <v-col cols="12" lg="6" class="py-2 py-lg-0" v-if="canShowUpgrade()">
                    <v-btn depressed class="" dark block color="#7356F6" :to="'/' + workspaceId + '/pricing'">
                      Upgrade
                    </v-btn>
                  </v-col>
                  <v-col cols="12" lg="6" class="py-2 py-lg-0" v-if="canShowManage()">
                    <v-btn depressed dark outlined block color="#7356F6" class="" :to="'/' + workspaceId + '/pricing'">
                      Manage
                    </v-btn>
                  </v-col>
                  <v-col cols="12" lg="6" class="py-2 py-lg-0" v-if="canShowResume()">
                    <v-btn depressed outlined class="" @click="resumeSubscription()" dark block :loading="resumeLoading" color="#7356F6">
                      Resume
                    </v-btn>
                  </v-col>
                  <v-col cols="12" lg="6" class="py-2 py-lg-0" v-if="canShowCancel()">
                    <v-btn class="" depressed outlined block @click="openConformationDialog = true" :loading="cancelLoading"
                      color="error">
                      Cancel
                    </v-btn>
                  </v-col>
                </v-row>
                
              </v-col>
            </v-row>
          </v-col>
          
          <!-- <v-col cols="12" md="4">
            <v-card flat
              class="plan-price-card pa-4 d-flex flex-column align-center justify-center rounded-lg fill-height">
              <h6 class="plan-price-card-heading">{{ planDisplayDetails.plan }} Account</h6>
              <p>
                <span class="plan-price font-weight-bold">
                  {{ planDisplayDetails.currencySymbol }}{{ planDisplayDetails.price }}</span>
              </p>
              <v-btn color="#7356F6" dark class="rounded-lg mt-2 mb-4" :to="'/' + workspaceId + '/pricing'" depressed>
                View All Plans
              </v-btn>
              <p class="text-center bottom-text">
                Upgrade now to make the most out of BugSmash!
              </p>
            </v-card>
          </v-col> -->
        </v-row>
      </v-card>
    </div>

    <v-dialog v-model="openConformationDialog" width="500">
      <v-card>
        <v-card-text class="text-center pt-5">
          Your plan will be canceled,<br />
          But is still available until the end of your billing period.
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center">
          <v-btn color="primary" text @click="openConformationDialog = false">
            Go Back
          </v-btn>
          <v-btn color="red" text @click="cancelSubscription">
            Cancel Plan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-template v-if="canShowCompletePayment()">
      <v-divider></v-divider>
      <div class="d-flex justify-space-between pa-4">
        <p class="d-flex align-center">
          Complete your payment to continue subscription
        </p>
        <div>
          <v-btn class="ms-2" elevation="2" @click="cancelSubscription" v-if="canShowCancel()" color="error">
            cancel
          </v-btn>
          <v-btn elevation="2" class="ms-2" @click="complatePayment()" dark color="teal">
            Complete Payment
          </v-btn>
        </div>
      </div>
    </v-template>

  </div>
</template>

<script>
import { fetchPlanDetailsByProductId, FREE_PLAN_DETAILS } from "@/utils/helpers";
import { mapGetters } from "vuex";

export default {
  name: "ActivePlanComponent",
  props: ["workspaceId"],
  computed: {
    ...mapGetters(["isLoggedIn", "user"]),
    planDisplayDetails: function () {
      var obj = {};
      if (this.activePlan?.subscription) {

        var plan = this.activePlan.stripe_price
        var planConstants = fetchPlanDetailsByProductId(plan.product)
        if (plan.product === process.env.VUE_APP_PRO_PRODUCT_ID) {
          obj.plan = planConstants.name;
          obj.duration = plan.recurring.interval;
          obj.price = plan.unit_amount / 100;
          obj.features = planConstants.features
        } else if (
          plan.product === process.env.VUE_APP_ENTERPRISE_PRODUCT_ID
        ) {
          obj.plan = planConstants.name;
          obj.duration = plan.recurring.interval;
          obj.price = plan.unit_amount / 100;
          obj.features = planConstants.features
        } else {
          obj.plan = FREE_PLAN_DETAILS.name
          obj.duration = "month";
          obj.price = 0;
          obj.features = FREE_PLAN_DETAILS.features
        }


        if (obj.duration == 'year')
          obj.price = Math.round(obj.price / 12)
        if (plan)
          obj.currencySymbol = this.getSymbol(plan.currency)
      } else {
        obj.plan = FREE_PLAN_DETAILS.name
        obj.duration = "month";
        obj.price = 0;
        obj.features = FREE_PLAN_DETAILS.features
        obj.currencySymbol = this.getSymbol()
      }
      // console.log(obj)
      return obj;
    },
  },

  data() {
    return {
      activePlan: null,
      pro_plan_id: process.env.PRO_PRODUCT_ID,
      enterprise_plan_id: process.env.ENTERPRISE_PRODUCT_ID,
      duration: "",
      email: "",
      password: "",
      plan: "",
      loginLoading: false,
      dialog: false,
      openConformationDialog: false,
      plans: [],
      subscriptionEndDate: null,
      price: "",
      cancelLoading: false,
      resumeLoading: false,
      initialLoading: false,
    };
  },

  methods: {

    getSymbol(currency) {
      switch (currency) {
        case 'usd':
          return '$'
        case 'inr':
          return '₹'
        default:
          return `$`
      }
    },
    async fetchPlanDetails() {
      this.initialLoading = true;
      let planRes = await this.$http.get(
        `/subscriptions/${this.workspaceId}/plan`
      );
      let planData = planRes.data.data;
      this.subscriptionEndDate = planRes.data.data;
      this.activePlan = planData
      this.initialLoading = false;
      // this.$store.dispatch("storeActivePlan", planData);
    },
    canShowManage() {
      if (this.activePlan) {
        if (this.activePlan?.subscription_status?.active) return true;
      }
      return false;
    },

    canShowResume() {
      if (this.activePlan) {
        if (
          this.activePlan?.subscription_status?.active &&
          this.activePlan?.subscription_status?.onGracePeriod
        )
          return true;
      }
      return false;
    },
    canShowCancel() {
      if (this.activePlan) {
        if (
          this.activePlan.subscription_status?.active &&
          !this.activePlan.subscription_status?.canceled
        )
          return true;
      }
      return false;
    },
    canShowCompletePayment() {
      if (this.activePlan) {
        if (this.activePlan.subscription_status?.hasIncompletePayment)
          return true;
      }
      return false;
    },
    complatePayment() {
      window.location.href = this.activePlan.url;
    },
    canShowUpgrade() {
      return !this.canShowManage();
    },
    async cancelSubscription() {
      this.openConformationDialog = false;
      this.cancelLoading = true;
      let response = await this.$http.post(
        `/subscriptions/${this.workspaceId}/cancel`
      );

      this.$root.vtoast.show({ message: response.data.message });
      this.cancelLoading = false;
      this.fetchPlanDetails();
    },
    async resumeSubscription() {
      this.resumeLoading = true;
      let response = await this.$http.post(
        `/subscriptions/${this.workspaceId}/resume`
      );

      this.$root.vtoast.show({ message: response.data.message });
      this.resumeLoading = false;
      this.fetchPlanDetails();
    },
    closeDialog(value) {
      this.dialog = value;
    },
    formatDate(date) {
      const options = { day: "2-digit", month: "short", year: "numeric" };
      const formattedDate = new Date(date).toLocaleDateString("en-US", options);

      // Split the formatted date into day, month, and year parts
      const [month, day, year] = formattedDate.split(" ");

      // Convert the month abbreviation to uppercase
      const capitalizedMonth = month.toUpperCase();

      // Set the formatted date with uppercase month abbreviation and desired format
      return `${day} ${capitalizedMonth} ${year}`;
    },
  },
  async mounted() {

    if (this.isLoggedIn) {
      this.initialLoading = true;
      try {
        await this.fetchPlanDetails();
        this.initialLoading = false;

      } catch (e) {
        console.log(e);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.v-application p {
  margin-bottom: 0px;
}

.app-heading {
  font-size: 1rem;
}

.included-heading {
  color: #000;
}

.plan-card-text {
  font-size: .875rem;
}

.tick-icon {
  font-size: 1.25rem;
}

.offering-text {

  span {
    font-weight: 500;
    font-size: .875rem;
  }
}

.plan-price-card {
  border: 1px solid lightgray;
  background-color: rgb(243, 243, 243);

  &-heading {
    font-size: 1rem;
  }

  .plan-price {
    font-size: 3.25rem;
    color: #7356F6;
  }

  .price-currency {
    color: gray;
    font-weight: 600;
  }

  .bottom-text {
    font-size: .75em;
  }
}

.curr-plan-price {
  font-weight: 700;
  color: #000;
  font-size: 2rem;
  line-height: 1;
}

.per-month {
  font-weight: 500;
  color: lighten(#000000, 30%);
  line-height: 1;
}

.not-subscribed-text {
  font-size: .875rem;
  color: lighten(#000000, 40%);
}
</style>
