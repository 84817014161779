<template>
   <v-footer color="grey lighten-4" padless>
      <v-row justify="center" no-gutters>
         <v-col class="py-4 text-center black--text" cols="12">
            {{ new Date().getFullYear() }} © SolutionWise. All Rights Reserved
         </v-col>
      </v-row>
   </v-footer>
</template>

<script>
   export default {
      data: () => ({}),
   }
</script>