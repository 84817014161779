<template>
  <div class="container pa-0">
    <PersonalInfo></PersonalInfo>
    <!-- <ActivePlanComponent></ActivePlanComponent> -->
  </div>
  <!-- <div class="container">
    <ActivePlanComponent></ActivePlanComponent>
    

    <v-card class="mx-auto my-12 mt-4" outlined max-width="600" elevation="3">
      <div class="pa-4">
        <P class="font-weight-bold text-h5">My Details</P>
        <p class="font-weight-light my-3">First Name: {{ this.$store.state.user.first_name }}</p>
        <p class="font-weight-light mb-5">Last Name: {{ this.$store.state.user.last_name }}</p>

      </div> -->

      <!-- <v-divider></v-divider>
      <div class="d-flex justify-space-between pa-4">
        <p class="d-flex align-center">Manage your subscription</p>
        <v-btn elevation="2">Open Portal</v-btn>
      </div> -->
    <!-- </v-card> -->
    <!-- <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar dark color="#7e64ed">
            <v-spacer></v-spacer>
            <v-btn icon dark @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <hr />
          <pricing></pricing>
        </v-card>
      </v-dialog> -->
  <!-- </div> -->
</template>

<script>
import { mapGetters } from "vuex";
import PersonalInfo from "./PersonalInfo.vue";

// import ActivePlanComponent from "./ActivePlanComponent.vue";
export default {
    name: "ProfileInfo",
    components: { PersonalInfo },
    data() {
        return {
            dialog: false,
        };
    },
    computed: {
        ...mapGetters(["fullName",'user']),
    },
};
</script>

<style lang="scss" scoped>
.v-application p {
  margin-bottom: 0px;
}
</style>
