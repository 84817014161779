<template>
   <div>
      <v-container class="pb-8">
         <v-btn to="/" text class="font-weight-black black--text px-0 mb-3 mb-lg-0" depressed>
            <span class="arrow">&#10140;</span> <span class="ms-1">My Apps</span>
         </v-btn>
         <v-card elevation="0">
            <h1 class="font-weight-black mb-2 page-header text-md-center">My Profile</h1>
            <p class="mb-4 text-md-center page-para">Your personal details </p>
            <div class="cards-wrapper pa-4 pa-md-6">
               <profile></profile>
            </div>
         </v-card>
      </v-container>
   </div>
</template>

<script>
   import Profile from '@/components/Profile.vue';

   export default{
      name: "ProfileView",
      components: { Profile }
   }
</script>

<style lang="scss" scoped>
   .arrow{
      transform: rotate(180deg);
   }

   .page-header{
      font-size: clamp(2rem, 3vw, 2.5rem);
   }

   .page-para{
      color: lighten(#000000, 60%);
   }

   .cards-wrapper{
      background-image: url(../assets/bug-bg.svg);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: bottom 10% right 2.5%;
      background-color: #F2F3F4;
      border-radius: 1.25rem;
   }

</style>