<template>
   <div>
      <v-skeleton-loader type="button" height="28" class="ms-2" v-if="menuLoader" dark></v-skeleton-loader>
      <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="200" offset-y v-else>
         <template v-slot:activator="{ on, attrs }">
            <v-btn color="transparent" small dark v-bind="attrs" v-on="on">
               {{ selectedWorkspace?.name ?? ""}}
               <v-icon small right v-if="selectedWorkspace">mdi-chevron-down-circle-outline</v-icon>
            </v-btn>
         </template>

         <v-card>
            <v-list class="">
               <v-list-item class="d-flex align-center">
                  <v-list-item-avatar color="#2F3547" width="50" height="50" class="rounded-lg">
                     <span class="white--text font-weight-black workspace-letter text-h6">{{ selectedWorkspace?.name?.charAt(0) }}</span>
                  </v-list-item-avatar>

                  <v-list-item-content style="max-width: 180px;">
                     <v-list-item-title class="black--text workspace-name mb-1">
                        {{ selectedWorkspace?.name }}
                     </v-list-item-title>
                     <v-list-item-subtitle class="settings-button px-0" @click="goToWorkspaceSettings(selectedWorkspace?.id)" v-if="isOwner">
                        Workspace Settings
                        <v-icon small>mdi-arrow-right</v-icon>
                     </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                     <v-chip v-if="isOwner" pill small class="workspace-chip owner" text-color="black">
                        Owner
                     </v-chip>
                     <v-chip small pill class="workspace-chip guest" text-color="black" v-else>
                        Guest
                     </v-chip>
                  </v-list-item-action>
               </v-list-item>
            </v-list>

            <v-divider></v-divider>

            <v-list class="py-0" dense  v-if="filteredWorkspaces.length > 0">
               <h6 class="text-caption mt-3 mb-2 black--text mx-4 px-0">Switch WorkSpace</h6>
               <v-list-item-group v-model="activeWorkspaceId" class="pb-3" active-class="active-workspace">
                  <v-list-item v-for="(workspace) in filteredWorkspaces" :key="workspace.id" :input-value="workspace.id">
                     <div class="d-flex align-center justify-space-between workspace-item" @click="selectWorkspace(workspace.id)">
                        <v-list-item-avatar  width="50" height="50" class="rounded-lg my-1 avatar">
                           <span class="font-weight-black workspace-letter guest-workspace text-h6">{{ workspace.name?.charAt(0) }}</span>
                        </v-list-item-avatar>
                        <v-list-item-content>
                           <v-list-item-title class="black--text workspace-name">
                              {{ workspace.name }}
                           </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                           <v-chip v-if="!isOwner" pill small class="workspace-chip owner" text-color="black">
                              Owner
                           </v-chip>
                           <v-chip small pill class="workspace-chip guest" text-color="black" v-else>
                              Guest
                           </v-chip>
                        </v-list-item-action>
                     </div>
                  </v-list-item>
               </v-list-item-group>
            </v-list>

         </v-card>
      </v-menu>
   </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
   name: "WorkspaceMenuCard",
   data: () => ({
      menu: false,
      workspaces: [],
      menuLoader: false,
      activeWorkspaceId: null,
   }),
   computed: {
      ...mapGetters(["user","activeWorkspace"]),
      filteredWorkspaces() {
         return this.workspaces.filter(workspace => workspace.id !== this.activeWorkspaceId);
      },
      selectedWorkspace(){
         return this.workspaces.find(workspace => workspace.id  == this.activeWorkspaceId);
      },
      isOwner(){
         return this.selectedWorkspace?.created_by == this.user.id
      }
   },
   watch:{
      activeWorkspace(){      
         
      }
   },
   methods: {
      closeMenu() {
         this.menu = false
      },

      goToWorkspaceSettings(id) {
         this.closeMenu()
         this.$router.push({ name: 'Settings', params: { id } });
         
      },

      async fetchWorkspaces() {
         this.menuLoader = true
         try {
            let response = await this.$http.get(`/workspaces`);
            this.workspaces = response.data.data;
            this.$store.dispatch("storeWorkspaces", response.data.data);
            
            this.activeWorkspaceId = this.workspaces.find(workspace => workspace.created_by === this.user.id).id;  
            let usersDefaultWorkspaceId = this.workspaces.find(workspace => workspace.created_by === this.user.id && workspace.type == 'default').id;  
            var doesPreviouslySelectedWorkspaceExist = this.workspaces.find(workspace => workspace.id == this.activeWorkspace)
            if(doesPreviouslySelectedWorkspaceExist){
               
               this.activeWorkspaceId = this.activeWorkspace
            }
            else{
               this.activeWorkspaceId = usersDefaultWorkspaceId
            }
            this.$store.dispatch("storeActiveWorkspace", this.activeWorkspaceId);
         } catch (error) {
            console.log(error);
         } finally {
            this.menuLoader = false
         }
      },

      selectWorkspace(workspaceId) {
         this.activeWorkspaceId = workspaceId;
         this.$store.dispatch("storeActiveWorkspace", this.activeWorkspaceId);     
         this.closeMenu()     
         if (this.$route.name !== 'dashboard') {
            this.$router.push({ name: 'dashboard' });
         }
      }
   },

   mounted() {      
      this.fetchWorkspaces();
      
   }
}
</script>

<style lang="scss" scoped>
.settings-button {
   cursor: pointer;
   color: lighten( #000000, 60%);
   font-size: .75rem;
   text-decoration: underline;
}

.workspace-chip{
   font-weight: 500 !important;
   color: #000;

   &.owner {
      background-color: lighten(#7E64ED,  30%) ;
   }

   &.guest{
      background-color: #FDEDB4;
   }
}

.avatar {
   background-color: transparent;
   border: 1px solid lighten( #000000, 60%);

   span{
      color: lighten( #000000, 60%);
   }
}

.workspace-letter{
   font-size: 1.5rem !important;
}

.workspace-name{
   font-size: 1rem !important;
}

.workspace-item{
   width: 100%;
}
</style>