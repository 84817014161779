import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        isLoggedIn: false,
        accessToken: null,
        activeWorkspace: null,
        workspaces:[],
        user: {
            id: null,
            first_name: null,
            last_name: null,
            step_no: 1,
            name: null,
            email: null,
            timezone: null,
            email_verified_at: null,
            created_at: null,
            updated_at: null,
            deleted_at: null,
        },
        highlightedReviewIds: [],
        lockedReviewIds: [],
        loginDialog: false,
        activePlan:null
        
    },
    getters: {
        user(state){
            return state.user
        },
        fullName(state) {
            return `${state.user.first_name} ${state.user.last_name}`;
        },
        loginDialog(state) {
            return state.loginDialog;
        },
        isLoggedIn(state) {
            return state.accessToken ? true : false;
        },
        getHighlightedReviewIds(state) {
            return state.highlightedReviewIds;
        },
        getLockedReviewIds(state) {
            return state.lockedReviewIds;
        },
        activePlan(state){
            return state.activePlan;
        },
        activeWorkspace(state){
            return state.activeWorkspace
        },
        workspaces(state){
            return state.workspaces
        },
    },
    mutations: {
        setWorkspaces(state, workspaces){
            state.workspaces = workspaces;
        },
        setActiveWorkspace(state, activeWorkspace){
            state.activeWorkspace = activeWorkspace;
        },
        setLoginDialog(state, open) {
            state.loginDialog = open;
        },
        setIsLoggedIn(state, isLoggedIn) {
            state.isLoggedIn = isLoggedIn;
        },
        storeUserInfo(state, user) {
            state.user = user;
        },
        addHighlightedReviewId(state, reviewId) {
            state.highlightedReviewIds.push(reviewId);
        },
        removeHighlightedReviewId(state, reviewId) {
            state.highlightedReviewIds.splice(
                state.highlightedReviewIds.indexOf(reviewId),
                1
            );
        },
        clearHighlightedReviewId(state) {
            state.highlightedReviewIds = [];
        },
        addLockedReviewId(state, reviewId) {
            state.lockedReviewIds.push(reviewId);
        },
        removeLockedReviewId(state, reviewId) {
            state.lockedReviewIds.splice(
                state.lockedReviewIds.indexOf(reviewId),
                1
            );
        },
        clearLockedReviewId(state) {
            state.lockedReviewIds = [];
        },
        setAccessToken(state,accessToken){
            state.accessToken = accessToken
        },
        storeActivePlan(state,activePlan){
            state.activePlan = activePlan
        }
    },
    actions: {
        storeWorkspaces({commit}, workspaces){                        
            commit("setWorkspaces", workspaces);            
        },
        storeActiveWorkspace({commit}, activeWorkspace){            
            localStorage.setItem("activeWorkspace", activeWorkspace);
            commit("setActiveWorkspace", activeWorkspace);            
        },
        accessToken({commit}, accessToken){
            localStorage.setItem("accessToken", accessToken);
            commit("setAccessToken", accessToken);
        },
        userLoggedIn({ commit }) {
            commit("setIsLoggedIn", true);
        },
        userLoggedOut({ commit }) {
            commit("setIsLoggedIn", false);
            commit("setAccessToken", null);
            localStorage.removeItem("accessToken")
            
        },
        openLoginModal({ commit }) {
            commit("setLoginDialog", true);
        },
        closeLoginModal({ commit }) {
            commit("setLoginDialog", false);
        },
        storeUserInfo({ commit }, user) {
            commit("storeUserInfo", user);
        },
        storeActivePlan({ commit }, activePlan) {
            commit("storeActivePlan", activePlan);
        },
        addHighlightedReviewId({ commit, state }, reviewId) {
            if (!state.highlightedReviewIds.includes(reviewId)) {
                commit("addHighlightedReviewId", reviewId);
            }
        },
        removeHighlightedReviewId({ commit, state }, reviewId) {
            if (state.highlightedReviewIds.includes(reviewId)) {
                commit("removeHighlightedReviewId", reviewId);
            }
        },
        clearHighlightedReviewId({ commit }) {
            commit("clearHighlightedReviewId");
        },
        addLockedReviewId({ commit, state }, reviewId) {
            if (!state.lockedReviewIds.includes(reviewId)) {
                commit("addLockedReviewId", reviewId);
            }
        },
        removeLockedReviewId({ commit, state }, reviewId) {
            if (state.lockedReviewIds.includes(reviewId)) {
                commit("removeLockedReviewId", reviewId);
            }
        },
        clearLockedReviewId({ commit }) {
            commit("clearLockedReviewId");
        },
    },
});
