<template>
   <section class="section d-flex flex-column flex-md-row">
      <div class="section-title-wrapper">
         <h4 class="section-heading">Personal Information</h4>
         <p class="mb-0 section-subheading">Your Name would be publicly visible when you share feedback.</p>
      </div>
      <div class="section-from-wrapper flex-grow-1">
         <!-- <div class="d-flex align-center mb-6">
            <v-avatar tile class="rounded-lg avatar-wrapper" size="80">
               <img :src="avatarImage" class="avatar-iamge">
            </v-avatar>
            <div class="ms-5">
               <v-btn class="rounded-lg mb-2" depressed dark color="#7356F6" :loading="isSelecting" @click="changeAvatar">
                  Change Avatar
               </v-btn>
               <input
                  ref="uploader"
                  class="d-none"
                  type="file"
                  accept="image/*"
                  @change="onFileChanged"
               >
               <p class="mb-0 text-caption">JPG, GIF or PNG. 1MB max</p>
            </div>
         </div> -->
         <v-row class="">
            <v-col cols="12" sm="6" class="">
               <v-text-field disabled label="First Name" :value="user.first_name" hide-details outlined dense background-color="white"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
               <v-text-field disabled label="Last Name" :value="user.last_name" hide-details outlined dense background-color="white"></v-text-field>
            </v-col>
            <v-col cols="12">
               <v-text-field :value="user.email" label="Email Address" disabled readonly hide-details outlined dense background-color="white"></v-text-field>
            </v-col>

            <!-- <v-col>
               <v-btn class="rounded-lg mb-2 px-8" depressed dark color="#7356F6">
                  Save
               </v-btn>
            </v-col> -->
         </v-row>
      </div>
   </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
   name: "PersonalInfo",
   computed: {
        ...mapGetters(["fullName",'user']),
    },
   data() {
      return {
         avatarImage: null,
         isSelecting: false,
         items: ['Foo', 'Bar', 'Fizz', 'Buzz'],
      };
   },

   methods: {
      onFileChanged(e) {
         if (e == undefined) return
         
         const reader = new FileReader()

         reader.onload = (e) => {
            this.avatarImage = e.target.result
         }

         if(e.target.files[0]){
            reader.readAsDataURL(e.target.files[0]);
         }
      },

      changeAvatar() {
         this.isSelecting = true
         window.addEventListener('focus', () => {
            this.isSelecting = false
         }, { once: true })
         this.$refs.uploader.click()
      }
   }
   
};
</script>

<style lang="scss" scoped>
   .section{
      gap: 1.5rem;
   }

   .section-title-wrapper{
      max-width: 16.25rem;
      flex-shrink: 0;
      .section-heading{
         font-weight: 800;
         letter-spacing: .5px;
         font-size: 1.125rem;
         margin-bottom: 6px;
      }

      .section-subheading{
         line-height: 1.4;
      }
   }

   .section-from-wrapper{
      .avatar-wrapper{
         border: 1px solid lightgrey;
         background-color: white;

         .avatar-iamge{
            object-fit: cover;
         }
      }
   }

   @media only screen and (min-width: 960px){
      .section{
         gap: 2rem;
      }
   }
</style>