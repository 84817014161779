<template>
  <v-container fluid class="screen mx-auto my-6 my-md-8 my-lg-10">
    <div id="img-container" ref="imageContainer">
      <img
        ref="selectedImage"
        :src="$getStorageURL(selectedScreen.path)"
        alt
        class="screen-selected-img"
      />
      <div
        :key="review.id"
        @mouseenter="addHighlight(review.id)"
        @mouseleave="removeHighlight(review.id)"
        @click="toggleHighlight(review.id)"
        :class="'img-markers '
           +(getHighlightedReviewIds.includes(review.id) ? 'highlighted ' : ' ')
           +(review.status === 'closed' ? 'review-closed' : '')"
        v-for="review in selectedScreen.reviews"
        :style="`top: ${review.y*heightAdjustmentFactor}px; left: ${review.x*widthAdjustmentFactor}px;`"
      >{{ review.order }}</div>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "VisualizerScreen",

  computed: {
    ...mapGetters(["getHighlightedReviewIds", "getLockedReviewIds"])
  },

  props: {
    selectedScreen: {
      required: true
    }
  },

  data() {
    return {
      heightAdjustmentFactor: 0,
      widthAdjustmentFactor: 0
    };
  },

  methods: {
    adjustHeightWidthFactors() {
      this.heightAdjustmentFactor = this.$refs.imageContainer
          ? (this.$refs.imageContainer.offsetHeight / this.selectedScreen.screen_height) : 0;
      this.widthAdjustmentFactor = this.$refs.imageContainer
          ? (this.$refs.imageContainer.offsetWidth / this.selectedScreen.screen_width) : 0;
    },
    toggleHighlight(reviewId) {
      if (this.getLockedReviewIds.includes(reviewId)) {
        this.removeHighlight(reviewId, true);
      } else {
        this.addHighlight(reviewId, true);
      }
    },
    addHighlight(reviewId, lock = false) {
      if (lock) {
        this.$store.dispatch("addLockedReviewId", reviewId);
      }
      this.$store.dispatch("addHighlightedReviewId", reviewId);
    },
    removeHighlight(reviewId, removeLock = false) {
      if (removeLock) {
        this.$store.dispatch("removeLockedReviewId", reviewId);
      }
      if (!this.getLockedReviewIds.includes(reviewId)) {
        this.$store.dispatch("removeHighlightedReviewId", reviewId);
      }
    }
  },

  mounted() {
    this.$refs.selectedImage.addEventListener('load', () => {
      this.adjustHeightWidthFactors();
    })
    window.addEventListener("resize", () => {
      this.adjustHeightWidthFactors();
    });
  }
};
</script>

<style lang="scss" scoped>
#img-container {
  position: relative;

  .screen-selected-img {
    width: 100%;
    border-radius: .5rem;
  }

  .img-markers {
    cursor: pointer;
    position: absolute;
    transform: translate(-50%, -50%);
    height: 30px;
    width: 30px;
    background-color: rgba(255, 0, 0, 0.6);
    border: 1px solid white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;

    &.review-closed {
      background-color: rgba(109, 197, 144, 0.9);
    }

    &.highlighted {
      // background-color: rgba(126, 100, 237, 0.5);
      border: 2px solid rgb(126, 100, 237);
    }
  }
}

.screen {
  //min-height: 42rem;
  width: 400px;
  max-width: 100%;
  background-color: #fff;
  border-radius: 1rem;
}
</style>