<template>
  <v-row >
    <v-col cols="12">
      <validation-observer v-slot="{handleSubmit}">
        <v-form @submit.prevent="handleSubmit(login)">
          <validation-provider rules="required|email" v-slot="{ errors }">
            <v-text-field
              v-model="email"
              label="Email"
              type="email"
              :error-messages="errors"
              class="mt-2"
              :disabled="loginLoading"
            ></v-text-field>
          </validation-provider>
          <validation-provider rules="required" v-slot="{ errors }">
            <v-text-field
              v-model="password"
              label="Password"
              type="password"
              :error-messages="errors"
              class="mt-2"
              :disabled="loginLoading"
            ></v-text-field>
          </validation-provider>
          <v-btn
            color="primary"
            type="submit"
            block
            class="mt-2"
            :loading="loginLoading"
            :disabled="loginLoading"
          >Login</v-btn>
        </v-form>
       
      </validation-observer>
      <div v-if="this.message" class="text-center mt-2"> <P style="color: red;">*{{ this.message }}</P></div>
      <div class="mt-5 text-start"><div class="mb-5"><GoogleSignIn></GoogleSignIn></div></div>
      
    </v-col>
  </v-row>
</template>

<script>
import GoogleSignIn from "@/components/GoogleSignIn.vue";
export default {
  name: "LoginVue",
  components: {
    GoogleSignIn,
  },
  data() {
    return {
      email: "",
      password: "",
      loginLoading: false,
      message:"",
    };
  },
  methods: {
    login() {
      this.loginLoading = true;
      this.$http
        .post(`/users/login`, { email: this.email, password: this.password })
        .then(response => {
          if (response.data.status) {
            this.$setToken(response.data.data.access_token, response.data.data.user);
            this.$closeLoginModal();

            const redirectPath = this.$route.query.redirect || '/';

            this.$router.push(redirectPath);
          } else {
            this.message = response.data.message;
          }
        })
        .finally(() => {
          this.loginLoading = false;
        });
    },
  }
};
</script>

<style lang="scss" scoped>
// .center {
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }
.v-application .primary {
    background-color: #7e64ed !important;
    border-color: #1976d2 !important;
}

</style>
