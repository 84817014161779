<template>
   <section class="section d-flex flex-column flex-md-row">
      <div class="section-title-wrapper">
         <h4 class="section-heading">Workspace Information</h4>
         <p class="mb-0 section-subheading">Basic information and list of apps associated with this workspace
         </p>
      </div>
      <div class="section-from-wrapper flex-grow-1">
         <v-skeleton-loader type="article" v-if="workspaceLoader"></v-skeleton-loader>
         <div v-if="!workspaceLoader">
            <div class="mb-2">
               <div class="d-flex align-center" v-if="!isEditing">
                  <h5 class="section-inner-heading">{{ workSpaceInfo?.name }}'s Workspace</h5>
                  <!-- <v-btn xsmall icon color="black" @click="startEditing">
                     <v-icon small>mdi-pencil-outline</v-icon>
                  </v-btn> -->
               </div>
               <!-- <div class="d-flex align-center" style="max-width: 400px;" v-else>
                  <v-text-field v-model="editedName" dense outlined hide-details class="section-inner-heading" single-line ref="editInput"></v-text-field>
                  <v-btn xsmall icon color="black" @click="saveEdit">
                     <v-icon small>mdi-check</v-icon>
                  </v-btn>
               </div> -->
            </div>
            <h6 class="text-subtitle-2 grey--text mb-2">List of apps in this workspace</h6>
            <apps-list-card :data="workSpaceInfo"></apps-list-card>
         </div>
      </div>
   </section>
</template>

<script>
import AppsListCard from '../components/AppsListCard.vue'
export default {
   name: "WorkspaceInfo",
   components: { AppsListCard },
   data() {
      return {
         workspaceLoader: false,
         workSpaceInfo: null,
         isEditing: false,
         editedName: '',
      };
   },

   methods: {
      getWorkspaceDetails() {
         this.workspaceLoader = true
         const workspaceId = this.$route.params.id;
         this.$http.get(`/workspaces/${workspaceId}`)
            .then(response => {
               this.workSpaceInfo = response.data.data
            })
            .catch((error) => {
               console.log(error)
            })
            .finally(() => {
               this.workspaceLoader = false
            });
      },

      // startEditing() {
      //    this.isEditing = true;
      //    this.editedName = this.workSpaceInfo.name;
      //    this.$nextTick(() => {
      //       this.$refs.editInput.focus();
      //    });
      // },
      // saveEdit() {
      //    this.workSpaceInfo.name = this.editedName;
      //    this.isEditing = false;
      // }
   },

   mounted() {
      this.getWorkspaceDetails()
   }

};
</script>

<style lang="scss" scoped>
.section {
   gap: 1.5rem;
}

.section-title-wrapper {
   width: 16.25rem;
   flex-shrink: 0;

   .section-heading {
      font-weight: 800;
      letter-spacing: .5px;
      font-size: 1.125rem;
      margin-bottom: 6px;
   }

   .section-subheading {
      line-height: 1.4;
   }
}

.section-from-wrapper {
   .section-inner-heading {
      font-size: 1rem;
      font-weight: 800;
      line-height: 1.7;
   }

}

@media only screen and (min-width: 960px) {
   .section {
      gap: 2rem;
   }
}
</style>