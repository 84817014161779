<template>
  <v-row class>
    <v-col cols="12" d-flex justify-center align-center>
      <v-btn
        x-small
        color="success"
        dark
        rounded
        :loading="statusUpdateLoader"
        :disabled="statusUpdateLoader"
        @click.stop="checkAndMark('resolve')"
        v-if="componentReview.status === 'open'"
      >Resolve</v-btn>
      <v-btn
        x-small
        color="error"
        dark
        rounded
        :loading="statusUpdateLoader"
        :disabled="statusUpdateLoader"
        @click.stop="checkAndMark('unresolve')"
        v-if="componentReview.status === 'closed'"
      >Unresolve</v-btn>
    </v-col>
    <v-snackbar
      v-model="snackbar.show"
      top
      right
    >
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="primary"
          text
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>
  
  <script>
export default {
  name: "UpdateBugStatusVue",
  props: ["review"],
  components: {},
  data() {
    return {
      snackbar: {
        show: false,
        text: ""
      },
      componentReview: this.review,
      statusUpdateLoader: false
    };
  },
  methods: {
    checkAndMark(action) {
      if (this.isLoggedIn) {
        if (action === "resolve") {
          this.resolveBug();
        } else {
          this.unResolveBug();
        }
      } else {
        this.$openLoginModal();
      }
    },
    resolveBug() {
      this.statusUpdateLoader = true;
      const status = "closed";
      this.$http
        .post(`/reviews/${this.componentReview.id}/update`, { status: status })
        .then(response => {
          if (response.data.status === 1) {
            this.componentReview.status = status;
            this.snackbar.text = "Bug Status Updated"
            this.snackbar.show = true
          }
        })
        .finally(() => {
          this.statusUpdateLoader = false;
        });
    },
    unResolveBug() {
      this.statusUpdateLoader = true;
      const status = "open";
      this.$http
        .post(`/reviews/${this.componentReview.id}/update`, { status: status })
        .then(response => {
          if (response.data.status === 1) {
            this.componentReview.status = status;
            this.snackbar.text = "Bug Status Updated"
            this.snackbar.show = true
          }
        })
        .finally(() => {
          this.statusUpdateLoader = false;
        });
    }
  }
};
</script>
  