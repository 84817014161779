<template>
   <v-card elevation="0" class="toolbar-container rounded-0 overflow-hidden">
      <v-container fluid class="loader-container" v-if="toolbarLoader">
         <v-skeleton-loader class="" type="list-item-avatar-three-line"></v-skeleton-loader>
      </v-container>
      <v-toolbar class="toolbar" color="white" elevation="0" height="auto" v-else>
         <v-container fluid class="mx-auto d-md-flex align-center px-0 px-md-3 toolbar-inner">
            <article class="d-flex align-center mb-4 mb-md-0">
               <div class="avatar-wrapper rounded-circle">
                  <v-avatar class="ma-md-4">
                     <img :src="$getStorageURL(appDetails?.image_path)" :alt="appDetails?.name">
                  </v-avatar>
               </div>
               <div class="ms-4">
                  <h6 class="text-subtitle-1 font-weight-bold">{{appDetails?.name}}</h6>
                  <p class="mb-0 text-caption">Created by: <span>{{appDetails?.owner?.first_name}} {{appDetails?.owner?.last_name}}</span></p>
               </div>
            </article>
            <v-spacer></v-spacer>
            <div class="d-flex align-center justify-space-between">
               <ul class="users-list d-flex ma-0 pa-0">
                  <li class="rounded-circle d-flex align-center justify-center user" v-for="user in appDetails?.users?.slice(0, 5)"
                     :key="user.id">
                     <v-menu open-on-hover top left offset-y >
                        <template v-slot:activator="{ on, attrs }">
                           <v-btn color="white" icon dark v-bind="attrs" v-on="on" class="">
                              {{ getUserInitials(user?.first_name) }}
                           </v-btn>
                        </template>

                        <div class="tooltip-content d-flex align-center pa-3">
                           <span>{{ user?.first_name }} {{ user?.last_name }}</span>
                        </div>
                     </v-menu>
                  </li>
                  <v-menu open-on-hover top left offset-y v-if="appDetails?.users?.length > 5">
                     <template v-slot:activator="{ on, attrs }">
                        <v-btn color="white" icon dark v-bind="attrs" v-on="on"
                           class="rounded-circle d-flex align-center justify-center extra-users">
                           +{{ appDetails?.users?.length - 5 }}
                        </v-btn>
                     </template>

                     <div class="tooltip-content d-flex flex-column pt-4 px-4 pb-2">
                        <div v-for="user in appDetails.users?.slice(5)" :key="user.id"
                           class="d-flex align-center mb-2 justify-space-between">
                           <span>{{ user?.first_name }} {{ user?.last_name }}</span>
                        </div>
                     </div>
                  </v-menu>
               </ul>
               <v-btn color="#7356f6" class="rounded-lg ms-6" depressed dark @click="shareDialog = true">
                  Share
               </v-btn>
            </div>
            <v-dialog v-model="shareDialog" width="500">
               <v-card>
                  <h3 class="pa-4 text-center">Share this Workspace</h3>
                  <div class="pa-4 pa-md-6 link-container">
                     <div class="d-flex align-center mb-6 justify-space-between">
                        <h5>Share link</h5>
                        <div class="d-flex align-center">
                           <span class="text-caption me-3">Anyone with the link has access</span>
                           <!-- <v-switch color="#7356f6" class="mt-0" v-model="hasAccess" inset hide-details></v-switch> -->
                        </div>
                     </div>
                     <v-row>
                        <v-col cols="12" sm="9" class="pb-0 pb-md-3">
                           <v-text-field background-color="white" label="Outlined" readonly v-model="shareLink" single-line outlined dense hide-details ref="link"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3">
                           <v-btn color="#7356f6" height="40" block depressed dark @click="copyLink">Copy</v-btn>
                        </v-col>
                     </v-row>
                  </div>
                  <div class="pa-4 pa-md-6" v-if="!hasAccess">
                     <v-row >
                        <v-col cols="12" sm="9" class="pb-0 pb-md-3">
                           <v-text-field background-color="white" type="email" label="Add Emails" single-line outlined dense hide-details></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3">
                           <v-btn color="grey lighten-1" height="40" block depressed dark >Send</v-btn>
                        </v-col>
                     </v-row>
                     <h5 class="mt-4 mb-2">Invited ({{ invitedUsers.length }})</h5>
                     <v-list class="invited-user-wrapper py-0">
                        <div v-for="user in invitedUsers" :key="user.id" class="d-flex py-2 invited-user align-center justify-space-between">
                           <span>{{ user?.name }}</span>
                           <v-btn text color="red" small class="px-0" @click="openRemoveUserDialog(user.id)">Remove</v-btn>
                        </div>
                     </v-list>
                  </div>
               </v-card>
            </v-dialog>

            <v-dialog v-model="removeUserDialog" width="500">
               <v-card class="px-4 py-6">
                  <p class="mb-4 text-center">
                     Are you sure you want to remove {{ getUsernameById(selectedUserId) }}?
                  </p>
                  <div class="d-flex align-center justify-center">
                     <v-btn color="red" depressed class="me-2" dark @click="removeUser(selectedUserId)">
                        Remove
                     </v-btn>
                     <v-btn color="#7356F6" class="ms-2" text @click="closeUserDialog()">
                        Close
                     </v-btn>
                  </div>
               </v-card>
            </v-dialog>
         </v-container>
      </v-toolbar>
   </v-card>
</template>

<script>

export default {
   name: 'AppInfoToolbar',
   data() {
      return {
         shareDialog: false,
         removeUserDialog: false,
         hasAccess: true,
         selectedUserId: null,
         toolbarLoader: false,
         appDetails: null,
         shareLink: '',
         invitedUsers: [
            { id: 31, name: 'Sarah connor' },
            { id: 41, name: 'Anna Smith' },
            { id: 51, name: 'John Doe' },
            { id: 61, name: 'Micheal Davis' },
         ]
      }
   },
   methods: {
      getUserInitials(str) {
         const firstLetters = str
            .split(' ')
            .map(word => word.charAt(0))
            .join('')
            .slice(0, 2);

         return firstLetters;
      },

      fetchAppDetails() {
         const appId = this.$route.params.appId;
         this.toolbarLoader = true
         this.$http
            .get(`/apps/${appId}`)
            .then(response => {
               if (response.data.status === 1) {
                  this.appDetails = response.data.data;
                  this.shareLink = this.appDetails.public_link
               }
               
            })
            .catch(() => { })
            .finally(() => {
               this.toolbarLoader = false;
            });
      },

      closeUserDialog(){
         this.removeUserDialog = false
      },

      openRemoveUserDialog(userId) {
         this.selectedUserId = userId;
         this.removeUserDialog = true;
      },

      removeUser(userId) {
         this.invitedUsers = this.invitedUsers.filter(user => user.id !== userId);
         this.closeUserDialog();
      },

      getUsernameById(userId) {
         const user = this.invitedUsers.find(user => user.id === userId);
         return user ? user.name : '';
      },

      async copyLink() {
         try {
            await navigator.clipboard.writeText(this.shareLink);
            this.$root.vtoast.show({ message: "Link Copied!", color: "success"})
         } catch (error) {
            this.$root.vtoast.show({ message: "Unable to copy link", color: "red"})
         }
      },
      
   },

   mounted() {
      this.fetchAppDetails()
   }
}

</script>

<style lang="scss" scoped>
.toolbar-container {
   border-bottom: 1px solid lightgray;
}

// .toolbar{
//    background-image: linear-gradient( #dedab438, #7356f626);
// }

.toolbar-inner {
   max-width: 90rem;
}

.avatar-wrapper {
   max-width: fit-content;
   border: 1px solid grey;
}

.users-list {
   list-style-type: none;

   .user {
      width: 3rem;
      height: 3rem;
      aspect-ratio: 1;
      background-color: rgb(180, 179, 179);
      color: #ffffff;
      text-transform: uppercase;
      border: 2px solid #ffffff;
      cursor: pointer;

      &:not(:first-child) {
         margin-left: -.625rem;
      }

      &:hover {
         border: 2px solid #7356F6;
         z-index: 1;
      }
   }

   .extra-users {
      width: 3rem;
      height: 3rem;
      aspect-ratio: 1;
      background-color: rgb(180, 179, 179);
      color: #ffffff;
      margin-left: -.625rem;
      border: 2px solid #ffffff;
   }
}

.tooltip-content {
   background-color: #ffffff;
}

.link-container{
   background-color: rgb(229, 229, 229);
}

.invited-user-wrapper{
   border-block: 1px solid lightgrey;
}

.invited-user:not(:last-child){
   border-bottom: 1px solid lightgray;
}
</style>