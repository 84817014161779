<template>
  <v-container fluid color="#fff" class="pa-0 mx-auto">
    <div class="fill-height " v-if="!screens.length && !appScreensLoader">
      <v-row align="stretch" justify="center" class="data-container my-6 mx-auto">
        <v-col cols="12" class="d-flex justify-center align-center">
          <div>
            <v-img width="300" height="auto" class="mx-auto img-fluid" src="../assets/no-feedbacks-img.svg"></v-img>
            <p class="mt-3 mb-1 text-center no-data-text">Nothing here yet!</p>
            <p class="mb-0">Save the screens & feedback from BugSmash mobile app to start appearing here.</p>
          </div>
        </v-col>
      </v-row>
    </div>
    <div v-if="appScreensLoader">
      <v-container fluid class="loader-container">
        <v-skeleton-loader class="" type="list-item-avatar-three-line, divider, actions, image, card-heading, image"></v-skeleton-loader>
      </v-container>
    </div>
    <div v-if="screens.length > 0">
      
      <v-container fluid color="#fff" class="mx-auto pa-0 main-container">
        <v-btn class="d-md-none" text color="black" @click="startSelectScreen" v-if="!screenSelector">
          <v-icon icon small left>mdi-arrow-left</v-icon> Screen Selector
        </v-btn>
        <app-info-toolbar/>

        <v-container fluid class="pa-0" id="review-container">
          
          <v-sheet class="custom-container mx-auto visualizer-grid" v-if="windowWidth >= 960">
            <section class="visualizer-grid--item visualizer-cards-container sticky-section">
              <visualizr-cards :screens="screens" :key="screens.length" @screenSelected="selectScreen" />
            </section>

            <section class="visualizer-grid--item visualizer-screens-container sticky-section">
              <visualizr-screen :selected-screen="selectedScreen" :key="selectedScreen.id" />
            </section>

            <section class="visualizer-grid--item bug-reports-container sticky-section">
              <bug-reports :reviews="selectedScreen.reviews" :key="selectedScreen.id" />
            </section>
          </v-sheet>
          
          <v-sheet class="mx-auto mobile-container" color="#DEDAB4" v-else>
            <div v-if="screenSelector">
              <visualizr-cards :screens="screens" :key="screens.length" @screenSelected="selectScreen" />
            </div>
            <div v-else>
              <section class="visualizer-grid--item visualizer-screens-container sticky-section">
                <visualizr-screen :selected-screen="selectedScreen" :key="selectedScreen.id" />
              </section>
              <bottom-slider-container ref="reportSlider">
                <bug-reports :reviews="selectedScreen.reviews" :key="selectedScreen.id" />
              </bottom-slider-container>
            </div>
          </v-sheet>
          
        </v-container>
      </v-container>
    </div>

  </v-container>
</template>

<script>
import BugReports from "@/components/BugReports.vue";
import VisualizrCards from "@/components/VisualizrCards.vue";
import VisualizrScreen from "@/components/VisualizrScreen.vue";
import BottomSliderContainer from "@/components/mobile/BottomSliderContainer.vue";
import AppInfoToolbar from "@/components/AppInfoToolbar.vue"
import { mapGetters } from "vuex";

export default {
  name: "VisualizerView",

  computed: {
    ...mapGetters(["getLockedReviewIds"])
  },

  watch: {
    "getLockedReviewIds.length": {
      handler(nextVal, prevVal) {
        if (nextVal > prevVal && nextVal === 1)
          this.$refs.reportSlider.openContainer();
      },
      deep: true
    }
  },

  components: {
    BugReports,
    VisualizrCards,
    VisualizrScreen,
    BottomSliderContainer,
    AppInfoToolbar
  },

  data() {
    return {
      windowWidth: window.innerWidth,
      screens: [],
      appScreensLoader: false,
      screenSelector: true,
      selectedScreen: {
        id: null,
        reviews: []
      }
    };
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  methods: {
    startSelectScreen() {
      this.screenSelector = true;
    },
    selectScreen(screenId) {
      this.selectedScreen = this.screens.find(screen => screen.id === screenId);
      this.screenSelector = false;
    },
    fetchAppScreens() {
      const appId = this.$route.params.appId;
      this.appScreensLoader = true;
      this.$http
        .get(`/preview/${appId}/screens`)
        .then(response => {
          if (response.data.status === 1) {
            this.screens = response.data.data;
            if (!this.selectedScreen.id && this.screens.length) {
              this.selectedScreen = this.screens[0];
            }
          }
        })
        .catch(() => { })
        .finally(() => {
          this.appScreensLoader = false;
        });
    },

    onResize() {
      this.windowWidth = window.innerWidth;
    }
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    }),
      this.fetchAppScreens();
  }
};
</script>

<style lang="scss" scoped>
.v-sheet>>>.v-toolbar__content {
  padding: 0 !important;
}

.visualizr-toolbar {
  border-bottom: 1px solid #f2f3f4;
}

.back-btn {
  min-width: 2.25rem !important;
}

.page-name {
  font-weight: 800;
  font-size: 1.5rem;
  line-height: 1.4;
}

.visualizer-grid {
  min-height: calc(100vh - 7.5rem);
  display: grid;
  grid-template-columns: 0 1fr 0;

  &--item {
    min-height: 100%;
  }
}

.sticky-section {
  position: static;
  top: 0;
  overflow: auto;
  height: calc(100vh - 7.5rem);
}

.visualizer-cards-container {
  background-color: #f2f3f4;
}

.visualizer-screens-container {
  background-color: #DEDAB4;
}

.mobile-container {
  min-height: calc(100vh - 4rem);
}

.data-container {
  background-image: url(../assets/bug-bg.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom 10% right 2.5%;
  background-color: #F2F3F4;
  border-radius: 1.25rem;
  min-height: calc(100vh - 9.5rem);
  max-width: 1200px;
}

.no-data-text {
  font-weight: 600;
}

.bug-reports-container{
  background-color: #F2F3F4;
}

@media only screen and (min-width: 600px) {
  .mobile-container {
    min-height: calc(100vh - 4.5rem);
  }

  .back-btn {
    background-color: transparent;
  }

  .page-name {
    font-size: 2rem;
  }

  .visualizer-grid {
    min-height: calc(100vh - 8rem);
  }

  .sticky-section {
    height: calc(100vh - 8rem);
  }
}

@media only screen and (min-width: 960px) {
  .visualizer-grid {
    grid-template-columns: 1fr 1.2fr 1fr;
    min-height: calc(100vh - 12.1rem);
  }

  .sticky-section {
    height: calc(100vh - 12.1rem);
  }
}

@media only screen and (min-width: 1264px) {
  .main-container {
    background-color: #F2F3F4;
    // background: linear-gradient(to right,
    //     #f2f3f4 0,
    //     #f2f3f4 50%,
    //     #ffffff 50%,
    //     #ffffff 100%);
  }

  .visualizer-grid {
    grid-template-columns: 1fr 1.2fr 1fr;
    min-height: calc(100vh - 12.1rem);
  }
}
</style>