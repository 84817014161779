<template>
  <div>    
    <v-container v-if="loading || !this.plans.length">
      <v-row>
        <v-col cols="12" md="6" lg="4" v-for="n in [1,2,3]" :key="n">
          <v-skeleton-loader type="card" class="mx-auto"></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="px-0 px-sm-3" v-else>
      <v-row>
        <v-col cols="12" class="pt-2 pb-4 text-center">
          <!-- <h2 class="plans-heading mt-0 mb-4">Choose a plan</h2> -->
          <v-btn-toggle v-model="duration" class="btns-container" group mandatory>
            <v-btn value="year" class="plans-btn" active-class="btn-bg"> Yearly </v-btn>
            <v-btn value="month" class="plans-btn" active-class="btn-bg"> Monthly </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
      <v-row class="cards-contanier">

        <v-col cols="12" md="6" lg="4" class="px-0 mx-auto px-sm-3">
          <v-card elevation="0" class="pricing-card pa-6 pa-sm-8 pro">
            <v-card-title class="d-inline-block text-start rounded-pill px-5 card-title">Basic Account</v-card-title>
            <v-card-text class="px-0 pb-0 my-5">
              <div class="main-price">
                ₹0
              </div>
              <span class="d-block per">per workspace per month</span>
            </v-card-text>
            <v-card-subtitle class="text-start pa-0 ma-0" v-if="duration === 'month'">Billed Monthly</v-card-subtitle>
            <v-card-subtitle class="text-start pa-0 ma-0" v-if="duration === 'year'">Billed Annually</v-card-subtitle>
            <v-divider class="my-4"></v-divider>
            <div class="my-6">
              <p class="offering-text d-flex align-center mb-4">
                <v-icon class="me-3 rounded-circle tick-icon" color="#7e64ed">mdi-check</v-icon>
                <span>2 Apps</span>
              </p>
              <p class="offering-text d-flex align-center mb-4">
                <v-icon class="me-3 rounded-circle tick-icon" color="#7e64ed">mdi-check</v-icon>
                <span>20 Screen Reviews Per App</span>
              </p>
              <p class="offering-text d-flex align-center mb-4">
                <v-icon class="me-3 rounded-circle tick-icon" color="#7e64ed">mdi-check</v-icon>
                <span>2 Users Per Workspace</span>
              </p>
              <p class="offering-text d-flex align-center mb-4">
                <v-icon class="me-3 rounded-circle tick-icon" color="#7e64ed">mdi-check</v-icon>
                <span>Public Share Links</span>
              </p>
              <p class="offering-text d-flex align-center mb-4">
                <v-icon class="me-3 rounded-circle tick-icon" color="#7e64ed">mdi-check</v-icon>
                <span>Only Android App</span>
              </p>
            </div>
            <!-- <v-btn v-if="!isLoggedIn" depressed block color="#7E64ED" dark @click="openLogin" height="40" class="rounded-pill">
              Subscribe
            </v-btn> -->

            <div>
              <v-btn v-if="activePlan?.subscription_status?.active == true" depressed block color="#7e64ed" height="40" class="rounded-pill" dark @click="cancelPlanCta()">
                Switch Plan
              </v-btn>
              <v-btn v-else outlined depressed block color="#7e64ed" height="40" class="rounded-pill disable-click">
                Currently Active
              </v-btn>
              
            </div>
          </v-card>
        </v-col>

        <v-col cols="12" md="6" lg="4" class="px-0 mx-auto px-sm-3">
          <v-card elevation="0" class="pricing-card pa-6 pa-sm-8 pro">
            <v-card-title class="d-inline-block text-start rounded-pill px-5 card-title">Pro Account</v-card-title>
            <v-card-text class="px-0 pb-0 my-5">
              <div class="main-price">
                {{ proPlanObj.displayPrice }}
              </div>
              <span class="d-block per">per workspace per month</span>
            </v-card-text>
            <v-card-subtitle class="text-start pa-0 ma-0" v-if="duration === 'month'">Billed Monthly</v-card-subtitle>
            <v-card-subtitle class="text-start pa-0 ma-0" v-if="duration === 'year'">Billed Annually</v-card-subtitle>
            <v-divider class="my-4"></v-divider>
            <div class="my-6">
              <p class="offering-text d-flex align-center mb-4">
                <v-icon class="me-3 rounded-circle tick-icon" color="#7e64ed">mdi-check</v-icon>
                <span>5 Apps</span>
              </p>
              <p class="offering-text d-flex align-center mb-4">
                <v-icon class="me-3 rounded-circle tick-icon" color="#7e64ed">mdi-check</v-icon>
                <span>50 Screen Reviews Per App</span>
              </p>
              <p class="offering-text d-flex align-center mb-4">
                <v-icon class="me-3 rounded-circle tick-icon" color="#7e64ed">mdi-check</v-icon>
                <span>5 Users Per Workspace</span>
              </p>
              <p class="offering-text d-flex align-center mb-4">
                <v-icon class="me-3 rounded-circle tick-icon" color="#7e64ed">mdi-check</v-icon>
                <span>Public + Private Share Links</span>
              </p>
              <p class="offering-text d-flex align-center mb-4">
                <v-icon class="me-3 rounded-circle tick-icon" color="#7e64ed">mdi-check</v-icon>
                <span>Android App + Web Portal</span>
              </p>
            </div>
            <v-btn v-if="!isLoggedIn" depressed block color="#7E64ED" dark @click="openLogin" height="40" class="rounded-pill">
              Subscribe
            </v-btn>

            <div v-else>
              <v-btn v-if="proPlanObj.isActive" outlined depressed block color="#7e64ed" height="40" class="rounded-pill disable-click">
                Currently Active
              </v-btn>
              <v-btn @click="openDialogIfSwitchPlan(proPlanObj)" v-else-if="processingPlanId != proPlanObj.id" :disabled="proPlanObj.isActive || processingPlanId.length > 0" depressed block color="#7e64ed" height="40" class="rounded-pill" dark>
                <span v-if="proPlanObj.canSwitchCurrentPlan">
                  Switch Plan
                </span>
                <span v-else>
                  Upgrade
                </span>
                <!-- <span v-if="enterprisePlanObj.isActive">
                  Downgrade
                </span>
                <span v-else-if="!proPlanObj.isActive && !enterprisePlanObj.isActive">
                  Upgrade
                </span> -->
              </v-btn>
              <v-progress-circular class="d-flex ma-auto align-center justify-center" indeterminate color="primary" v-else></v-progress-circular>
            </div>
            
          </v-card>
        </v-col>

        <v-col cols="12" md="6" lg="4" class="px-0 mx-auto px-sm-3">
          <v-card elevation="0" class="pricing-card pa-6 pa-sm-8 enterprise">
            <v-card-title class="d-inline-block text-start rounded-pill px-5 card-title">Enterprise Account</v-card-title>
            
            <v-card-text class="px-0 pb-0 my-5">
              <div class="main-price">
                {{ enterprisePlanObj.displayPrice }}
              </div>
              <span class="d-block grey--text per">per workspace per month</span>
            </v-card-text>
            <v-card-subtitle class="text-start white--text pa-0 ma-0" v-if="duration === 'month'">Billed Monthly</v-card-subtitle>
            <v-card-subtitle class="text-start white--text pa-0 ma-0" v-if="duration === 'year'">Billed Annually</v-card-subtitle>
            <v-divider class="my-4" color="white"></v-divider>
            <div class="my-6">
              <p class="offering-text d-flex align-center mb-4">
                <v-icon class="me-3 rounded-circle tick-icon" color="#ffffff">mdi-check</v-icon>
                <span class="white--text">Unlimited Apps</span>
              </p>
              <p class="offering-text d-flex align-center mb-4">
                <v-icon class="me-3 rounded-circle tick-icon" color="#ffffff">mdi-check</v-icon>
                <span class="white--text">Unlimited Screen Reviews</span>
              </p>
              <p class="offering-text d-flex align-center mb-4">
                <v-icon class="me-3 rounded-circle tick-icon" color="#ffffff">mdi-check</v-icon>
                <span class="white--text">Unlimited Users Per Workspace</span>
              </p>
              <p class="offering-text d-flex align-center mb-4">
                <v-icon class="me-3 rounded-circle tick-icon" color="#ffffff">mdi-check</v-icon>
                <span class="white--text">Public + Private Share Links</span>
              </p>
              <p class="offering-text d-flex align-center mb-4">
                <v-icon class="me-3 rounded-circle tick-icon" color="#ffffff">mdi-check</v-icon>
                <span class="white--text">Android App + Web Portal</span>
              </p>
            </div>
            <v-btn v-if="!isLoggedIn" depressed block color="#7E64ED" dark @click="openLogin" height="40" class="rounded-pill enterprise-btn">
              Subscribe
            </v-btn>

            <div v-else>
              <v-btn v-if="enterprisePlanObj.isActive" outlined depressed block color="#7e64ed" height="40" class="rounded-pill disable-click">
                Currently Active
              </v-btn>
              <v-btn @click="openDialogIfSwitchPlan(enterprisePlanObj)" v-else-if="processingPlanId != enterprisePlanObj.id" height="40" class="rounded-pill enterprise-btn" :disabled="enterprisePlanObj.isActive || processingPlanId.length > 0" depressed block color="#7E64ED" dark>
                
                <span v-if="enterprisePlanObj.canSwitchCurrentPlan">
                  Switch Plan
                </span>
                <span v-else>
                  Upgrade
                </span>
                <!-- <span v-if="enterprisePlanObj.canOnlySubscribe">
                  Upgrade
                </span>
                <span v-if="enterprisePlanObj.canSwitchCurrentPlan">
                  Upgrade
                </span> -->
              </v-btn>
              <v-progress-circular class="d-flex ma-auto align-center justify-center" indeterminate color="primary" v-else></v-progress-circular>
            </div>
            <!-- <div v-else>
              <v-btn @click="openDialogIfSwitchPlan(enterprisePlanObj)" v-if="processingPlanId != enterprisePlanObj.id" height="40" class="rounded-pill enterprise-btn"
                :disabled="enterprisePlanObj.isActive || processingPlanId.length > 0" depressed block color="#7E64ED"
                dark>

                <span v-if="enterprisePlanObj.canOnlySubscribe">
                  Upgrade
                </span>
                <span v-if="enterprisePlanObj.canSwitchCurrentPlan">
                  Upgrade
                </span>
                <span v-else-if="enterprisePlanObj.isActive">
                  Currently Active
                </span>
              </v-btn>
              <v-progress-circular class="d-flex ma-auto align-center justify-center" v-else indeterminate
                color="primary">
              </v-progress-circular>
            </div> -->
          </v-card>
        </v-col>

      </v-row>
    </v-container>
    <v-dialog v-model="openConformationDialog" width="500">
      <v-card>
        <v-card-text class="text-center pt-5">
          Are you sure, you want to switch?
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center">          
          <v-btn color="red" text @click="openConformationDialog = false">
            No
          </v-btn>
          <v-btn color="primary" text @click="subscribe()">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="alreadyCancelled" width="500">
      <v-card>
        <v-card-text class="text-center pt-5">
          Your subscription is already canceled. Your plan will automatically be switched at the end of the billing period.
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center">
          <v-btn color="primary" text @click="alreadyCancelled = false">
            ok
          </v-btn>          
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="openCancelDialog" width="500">
      <v-card>
        <v-card-text class="text-center pt-5">
          Are you sure, you want to switch?
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center">          
          <v-btn color="red" text @click="openCancelDialog = false">
            No
          </v-btn>
          <v-btn color="primary" text :loading="cancelLoading" @click="cancelSubscription">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "LoginVue",  
  computed: {
    ...mapGetters(["isLoggedIn", "user"]),
    proPlanObj: function () {
      var plan = this.plans.find((obj) => obj.product == process.env.VUE_APP_PRO_PRODUCT_ID && obj.recurring.interval == this.duration)  
      return this.getPlanDisplayObj(plan)
    },
    enterprisePlanObj: function () {
      // `this` points to the vm instance
      var plan = this.plans.find((obj) => obj.product == process.env.VUE_APP_ENTERPRISE_PRODUCT_ID && obj.recurring.interval == this.duration)      
      return this.getPlanDisplayObj(plan)
    }

  },

  data() {
    return {
      workspaceId: this.$route.params.workspaceId,
      pro_plan_id: process.env.PRO_PRODUCT_ID,
      enterprise_plan_id: process.env.ENTERPRISE_PRODUCT_ID,
      duration: "year",
      email: "",
      password: "",
      loginLoading: false,      
      loading: false,
      processingPlanId: "",
      openConformationDialog: false,
      openCancelDialog: false,
      plansObjInfo: null,
      plans:[],
      activePlan: null,
      cancelLoading: false,
      alreadyCancelled: false
    };
  },
  methods: {
    cancelPlanCta(){
      if(this.activePlan?.subscription_status?.canceled){
        this.alreadyCancelled = true
      }else{
        this.openCancelDialog = true
      }
    },
    getPlanDisplayObj(plan) {
      
      var amount = plan.unit_amount / 100
      if(plan.recurring.interval == 'year')
        amount = Math.round(amount / 12)
      
      plan.displayPrice = this.getSymbol(plan.currency) + "" + (amount)
      plan.canSwitchCurrentPlan = false
      plan.canOnlySubscribe = false
      if (this.activePlan?.subscription_status?.active) {
        plan.subscription_status = this.activePlan.subscription_status
        plan.isActive = plan.id == this.activePlan.subscription.stripe_price ? true : false
        plan.canSwitchCurrentPlan = !plan.isActive
      }
      else {
        plan.canOnlySubscribe = true
        plan.isActive = false
        plan.canSwitchCurrentPlan = false
      }


      return plan
    },

    openDialogIfSwitchPlan(planObj) {
      if (planObj.canSwitchCurrentPlan) {
        this.openConformationDialog = true
        this.plansObjInfo = planObj
      } else {
        this.plansObjInfo = planObj
        this.subscribe()
      }
    },

    openLogin() {
      this.$openLoginModal();
    },

    async subscribe() {
      this.openConformationDialog = false

      if (this.plansObjInfo.canOnlySubscribe) {
        this.processingPlanId = this.plansObjInfo.id
        let res = await this.$http.post(`/subscriptions/subscribe`, {
          workspace_id: this.workspaceId,
          price_id: this.plansObjInfo.id
        });
        this.processingPlanId = ""
        window.location.href = res.data.url
        this.$emit('dialog', false);
      }
      else if (this.plansObjInfo.canSwitchCurrentPlan) {
        this.processingPlanId = this.plansObjInfo.id
        let res = await this.$http.post(`/subscriptions/${this.workspaceId}/switch-plan`, {
          'price_id': this.plansObjInfo.id
        })

        if (res.data.status_code == "success") {
          this.$root.vtoast.show({ message: res.data.message })          
          this.$router.push({name:'Settings',params:{id:this.workspaceId}});
          // await helpers.fetchPlanDetails(this.workspaceId)
          await this.getMyWorkspacePlanDetails()
        }
        else if (res.data.status_code == "incomplete_payment") {
          window.location.href = res.data.data.url
        }
        this.processingPlanId = ""
        this.$emit('dialog', false);
      }

    },
    getProProductId() {
      return process.env.VUE_APP_PRO_PRODUCT_ID
    },
    getEnterpriseProductId() {
      return process.env.VUE_APP_ENTERPRISE_PRODUCT_ID
    },
    getSymbol(currency) {
      switch (currency) {
        case 'usd':
          return '$'
        case 'inr':
          return '₹'
        default:
          return `${currency} `
      }
    },
    async fetchPrices() {      
      let planRes = await this.$http.get(
        `/stripe/get-prices`
      );
      this.plans = planRes.data.data; 
    },

    async getMyWorkspacePlanDetails() {
      try {        
        let planRes = await this.$http.get(`/subscriptions/${this.workspaceId}/plan`);
          let planData = planRes.data.data          
          
          this.activePlan = planData
      } catch (e) {
        console.log(e)
      }
    },

    async cancelSubscription() {
      this.cancelLoading = true;
      let response = await this.$http.post(
        `/subscriptions/${this.workspaceId}/cancel`
      );

      this.$root.vtoast.show({ message: response.data.message });
      this.cancelLoading = false;
      this.openCancelDialog = false;
      this.$router.push({name:'Settings',params:{id:this.workspaceId}});
     }
  },
  async mounted() {
    await this.fetchPrices()    
    if (this.isLoggedIn) {
      this.loading = true
      await this.getMyWorkspacePlanDetails()
      this.loading = false
    }
  },
};
</script>

<style lang="scss" scoped>
.plans-heading {
  font-size: 2rem;
}

.btns-container {
  border: 2px solid #060310;
  border-radius: .325rem;
}

.v-btn-toggle:not(.v-btn-toggle--dense) .v-btn.v-btn.v-size--default {
  height: 2.25rem;
}

.v-btn-toggle>.v-btn.v-btn {
  border-radius: .125rem;
  padding-inline: 2rem;
}

.v-application .mt-16 {
  padding-top: 200px !important;
}

.v-application--is-ltr .v-btn-toggle>.v-btn.v-btn:first-child {
  border-top-left-radius: .125rem;
  border-bottom-left-radius: .125rem;
}

.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: rgb(126 100 237 / 76%) !important;
}

.theme--dark.v-btn.v-btn--disabled {
  color: rgb(255 255 255 / 79%) !important;
}

.v-application--is-ltr .v-btn-toggle>.v-btn.v-btn:last-child {
  border-top-right-radius: .125rem;
  border-bottom-right-radius: .125rem;
}

.plans-btn {
  font-weight: 600;
}

.cards-contanier {
  max-width: 1400px;
  margin-inline: auto;
}

.pro{
  border: 1px solid lightgray
}

.pricing-card {
  border-radius: .75rem;
  max-width: 25rem;
  margin-inline: auto;
}

.card-title {
  font-size: 1rem;
  font-weight: 400;
  text-transform: uppercase;
  background-color: #f2f3f4;
  padding-block: 3px;
  font-weight: 600;
}

.v-card__subtitle {
  font-size: 1rem;
  display: block;
  text-align: center;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

.main-price {
  font-size: 4rem;
  font-weight: 800;
  text-transform: uppercase;
  text-align: start;
  color: #7E64ED;
  line-height: 1;

  .per {
    // margin-top: 1rem;
    display: inline-block;
    text-transform: lowercase;
    font-size: 1.25rem;
    font-weight: 500;
    color: #707070;
  }
}

.tick-icon{
  padding: .25rem;
  font-size: 1.25rem;
  background-color: #ddd9f3;
}

.offering-text {
  max-width: 20rem;
  // margin-inline: auto;

  span {
    font-weight: 600;
    font-weight: 1.25rem;
  }
}

.enterprise{
  background-color: #060310;

  .card-title{
    background-color: #ffffff;
  }

  .tick-icon{
    background-color: #7E64ED;
  }
}

.disable-click {
  pointer-events: none
}


.v-btn-toggle--group > .v-btn.btn-bg {
  background-color: #060310 !important;
  color: #ffffff;
}
</style>
