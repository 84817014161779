import Vue from "vue";
import VueRouter from "vue-router";
import store from '../store'

import VisualizerView from "../views/VisualizerView.vue";
import MyApp from "../components/MyApp.vue";
import RedirectLogin from "../components/RedirectLogin.vue";
import PricingView from "../views/PricingView.vue"
import ProfileView from "../views/ProfileView.vue"
import SettingsView from "../views/SettingsView.vue"

// import { component } from "vue/types/umd";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        component: MyApp,
        name: "dashboard",
        meta: {
            title: '',        
            requireLogin: true,   
        }
    },
    {
        path: "/myapp",
        name: "MyApp",
        component: MyApp,
        meta: {
            title: '',        
            requireLogin: true,    
        }
    },
    {
        path: "/login",
        name: "RedirectLogin",
        component: RedirectLogin,
        meta: {
            hideNavbar: true,
            hideFooter: true
        }

    },
    {
        path: "/profile",
        name: "Profile",
        component: ProfileView,
        meta: {
            title: '',        
            requireLogin: true,   
        }
    },
    {
        path: "/settings/:id",
        name: "Settings",
        component:SettingsView,
        meta: {
            title: '',        
            requireLogin: true,   
        }
    },
    {
        path: "/share",
        name: "AppShare",
        
        redirect: (to) => {
            // the function receives the target route as the argument
            // we return a redirect path/location here.
            return { path: `/review/${to.query.app_id}`, query: {} };
        },
    },
    {
        path: "/review/:appId",
        name: "visualizr",
        component: VisualizerView,
        meta: {
            title: '',        
            requireLogin: true,  
            hideFooter: true 
        }
    },
    {
        path: "/:workspaceId/pricing",
        name: "pricing",
        component: PricingView,
        meta: {
            title: '',        
            requireLogin: true,   
        }
    },
    
    {
        path: "/about",
        name: "about",
        
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    const accessToken = store.state.accessToken;

    if (to.meta.requireLogin) {
        if (!accessToken) {
            if (to.name !== 'RedirectLogin') {
                next({
                    name: 'RedirectLogin',
                    query: { redirect: to.fullPath }, 
                });
            } else {
                next();
            }
        } else {
            next();
        }
    } else {
        if (accessToken) {
            // User is logged in, prevent access to login page
            next({
                name: 'dashboard', // You can change this to your dashboard route
            });
        } else {
            next();
        }
    }
});

export default router;
