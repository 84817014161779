<template>
  <v-item-group active-class="card-outline">
    <v-container fluid class="pt-5">
      <div class="px-3 mb-2 d-flex align-center justify-space-between">
        <h3 class="screens-heading font-weight-bold">Screens</h3>
        <v-btn-toggle v-model="activeTab" class="btns-container" dense mandatory group>
          <v-btn value="open" height="28" width="62" class="plans-btn ma-0 rounded-0 text-subtitle-2" active-class="btn-bg"> Open </v-btn>
          <v-btn value="closed" height="28" width="62" class="plans-btn ma-0 rounded-0 text-subtitle-2" active-class="btn-bg"> Closed </v-btn>
        </v-btn-toggle>
      </div>
      <v-row class="pa-5">
        <template v-if="filteredScreens.length > 0">
          <v-col v-for="screen in filteredScreens" :key="screen.id" cols="12" md="6" sm="6" class="pa-2 visualizr-card-container">
            <v-item v-slot="{  toggle }">
              <v-card class="d-flex align-center visualizr-card" :class="{ 'active-class': selectedScreen === screen.id }" color="#3A435E" flat @click="changeSelect(screen.id, toggle)">
                <div class=" flex-grow-1 text-center">
                  <div class="text-end card-header pa-2 visualizr-card-img-container">
                    <img :src="$getStorageURL(screen.path)" alt="" class="screen-preview-img">
                    <v-chip dark x-small color="#D06262">{{ getOpenReviews(screen) }} Open</v-chip>
                    <br>
                    <v-chip dark x-small color="#6DC590">{{ getClosedReviews(screen) }} Closed</v-chip>
                  </div>
                  <div class="text-start py-2 px-3 card-footer">
                    <h5 class="screen-name mb-0">{{ screen.name }}</h5>
                    <span class="card-time-and-date font-weight-medium"> {{ $formatDate(screen.created_at) }}</span>
                  </div>
                </div>
              </v-card>
            </v-item>
          </v-col>
        </template>
        <template v-else>
          <div class="no-screens-message">
            No {{ activeTab === 'open' ? 'open' : 'closed' }} screens.
          </div>
        </template>
      </v-row>
    </v-container>
  </v-item-group>
</template>

<script>
  import { globalMixin } from "@/utils/globalMixins";
export default {
  name: 'VisualizrCards',
  mixins: [globalMixin],
  props: ['screens'],

  filters: {
    displayDateTime(val) {
      // 4:50 PM, Mon, 12th June
      return (new Date(Date.parse(val))).toLocaleString()
    }
  },

  data: () => ({
    activeTab: "open",
    selectedScreen: null
  }),

  created() {
    if(this.$vuetify.breakpoint.mdAndUp) {
      this.activeTab = "open";
      this.selectedScreen = null;

      const firstOpenScreen = this.filteredScreens.find(
        (screen) => screen.reviews.some((review) => review.status === 'open')
      );

      if (firstOpenScreen) {
        this.changeSelect(firstOpenScreen.id);
      }
    }
  },

  watch: {
    activeTab() {
      if(this.$vuetify.breakpoint.mdAndUp) {
        const firstScreen = this.filteredScreens[0];
        if (firstScreen) {
          this.changeSelect(firstScreen.id);
        }
      }
    },
  },

  computed: {
    filteredScreens() {
      return this.screens.filter((screen) => {
        if (this.activeTab === "open") {
          return screen.reviews.some((review) => review.status === 'open');
        } else {
          return screen.reviews.every((review) => review.status === 'closed');
        }
      });
    },
  },

  methods: {
    getOpenReviews(screen) {
      return screen.reviews.filter((review) => review.status === 'open').length;
    },
    getClosedReviews(screen) {
      return screen.reviews.filter((review) => review.status === 'closed').length;
    },
    changeSelect(screenId, toggle) {
      this.selectedScreen = screenId;
      this.$emit('screenSelected', screenId);
      this.$store.dispatch('clearHighlightedReviewId');
      toggle();
    },
  },
}
</script>

<style lang="scss" scoped>
.visualizr-card-img-container {
  overflow: hidden;
  width: 100%;
  height: 120px;
}
.screen-preview-img {
  width: 100%;
}
.active-class {
  outline: 5px solid #7E64ED;
}

.visualizr-card {
  overflow: hidden;
  border-radius: .625rem;
  min-height: max-content;
  border: 1px solid #7E64ED;
}

.card-header {
  min-height: 7rem;
}

.screens-heading{
  font-size: 1rem;
  line-height: 1;
}

.card-footer {
  background-color: #fff;

  .screen-name {
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.2;
    word-break: break-word;
  }

  .card-time-and-date {
    font-size: .625rem;
    line-height: 1.2;
    color: lighten(#000, 60%);
  }
}

.btns-container {
  background-color: #ffffff;
  border: 2px solid #7E64ED;
  border-radius: .325rem;
}

.v-btn-toggle--group > .v-btn.btn-bg {
  background-color: #7E64ED !important;
  color: #ffffff;
}

@media only screen and (min-width: 600px) {
  .screens-heading {
    font-size: 1.25rem;
  }
}
</style>
 