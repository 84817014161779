<template>
   <section class="section d-flex flex-column flex-md-row">
      <div class="section-title-wrapper">
         <h4 class="section-heading">Current Plan</h4>
         <p class="mb-0 section-subheading">Details about your current plan</p>
      </div>
      <div class="section-from-wrapper flex-grow-1">
         <ActivePlanComponent :workspaceId="workspaceId"></ActivePlanComponent>
      </div>
   </section>
</template>

<script>
import ActivePlanComponent from '../components/ActivePlanComponent.vue'
export default {
   name: "PlanDetails",
   props:["workspaceId"],
   components: {ActivePlanComponent},   

   methods: {
      
   }
   
};
</script>

<style lang="scss" scoped>
   .section{
      gap: 1.5rem;
   }

   .app-heading{
    font-size: 1rem;
    line-height: 1;
  }

   .section-title-wrapper{
      width: 16.25rem;
      flex-shrink: 0;
      .section-heading{
         font-weight: 800;
         letter-spacing: .5px;
         font-size: 1.125rem;
         margin-bottom: 6px;
      }

      .section-subheading{
         line-height: 1.4;
      }
   }

   .section-from-wrapper{
      .section-inner-heading {
         font-size: 1rem;
         font-weight: 800;
         line-height: 1.7;
      }
      
   }

   @media only screen and (min-width: 960px){
      .section{
         gap: 2rem;
      }
   }
</style>