
import axios from "axios";

export const FREE_PLAN_DETAILS =   {
  product_id: null,
  name: "Basic",
  features: [
    "2 Apps",
    "20 Screen Reviews Per App",
    "2 Users Per Workspace",
    "Public Share Links",
    "Only Android App"
  ]
}

export const PLANS_CONSTANTS = [
  {
    product_id: process.env.VUE_APP_PRO_PRODUCT_ID,
    name: "Pro",
    features: [
      "5 Apps",
      "50 Screen Reviews Per App",
      "5 Users Per Workspace",
      "Public + Private Share Links",
      "Android App + Web Portal"
    ]
  },
  {
    product_id: process.env.VUE_APP_ENTERPRISE_PRODUCT_ID,
    name: "Enterprise",
    features: [
      "Unlimited Apps",
      "Unlimited Screen Reviews",
      "Unlimited Users Per Workspace",
      "Public + Private Share Links",
      "Android App + Web Portal"
    ]
  },
  FREE_PLAN_DETAILS

]

export async function fetchPlanDetails(workspaceId) {
    this.initialLoading = true
    let planRes = await axios.get(
      `/subscriptions/${workspaceId}/plan`
    );
    return planRes
    // store.dispatch("storeActivePlan", planRes.data.data);
  }

export function fetchPlanDetailsByProductId(productId) {

  var found = PLANS_CONSTANTS.find((obj)=>obj.product_id == productId)  
  return found

}  


